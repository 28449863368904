import { User } from '../../../graphql/sdk.generated'
import { useQuery } from 'react-query'
import { QueryFunction } from 'react-query/types/core/types'
import { handleRes } from '../handle-res'
import { sdk } from 'src/components'
import { recoverableErrorHandler } from 'src/core/util/handle-recoverable-error'

export type UserQueryKey = ReturnType<typeof userQueryKey>
export type UserQueryRes = User

export const userQueryKey = (userId: string | null) => ['user', userId]
export const userQueryFn =
  (): QueryFunction<UserQueryRes, UserQueryKey> =>
  ({ queryKey: [, _id] }) => {
    return sdk
      .user({ query: { _id } })
      .then(({ user }) => user) as Promise<UserQueryRes>
  }

const errMsg = 'Could not get user'

export const useUser = (_id: string): UserQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const queryKey = userQueryKey(_id)
  const queryFn = userQueryFn()
  const res = useQuery(queryKey, queryFn, options)
  return handleRes(res, errMsg)
}
