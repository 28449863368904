import { Nugget } from '../../../graphql/sdk.generated'
import { QueryFunction } from 'react-query/types/core/types'
import { useQuery } from 'react-query'
import { handleRes } from '../handle-res'
import { queryClient, sdk } from '../../../../components'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'

export type ThingQueryKey = ReturnType<typeof thingQueryKey>
export type ThingQueryRes = Nugget

export const thingQueryKey = (_id: string) => ['thing', _id]
const thingQueryFn =
  (): QueryFunction<ThingQueryRes, ThingQueryKey> =>
  ({ queryKey: [, _id] }) => {
    return sdk
      .nugget({ query: { _id } })
      .then(({ nugget }) => nugget) as Promise<ThingQueryRes>
  }

const errMsg = 'Could not get thing'

export const useThing = (_id: string): ThingQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const queryKey = thingQueryKey(_id)
  const queryFn = thingQueryFn()
  const res = useQuery(queryKey, queryFn, options)
  return handleRes(res, errMsg)
}

export const refreshThing = (_id: string) =>
  queryClient.invalidateQueries(thingQueryKey(_id))
