import { Trail } from '../../../graphql/sdk.generated'
import { QueryFunction, useQuery } from 'react-query'
import { handleRes } from '../handle-res'
import { queryClient, sdk } from '../../../../components'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'

export type BundleQueryKey = ReturnType<typeof bundleQueryKey>
export type BundleQueryRes = Trail

export const bundleQueryKey = (_id: string) => ['bundle', _id]
const bundleQueryFn =
  (): QueryFunction<BundleQueryRes, BundleQueryKey> =>
  ({ queryKey: [, _id] }) => {
    return sdk
      .trail({ query: { _id } })
      .then(({ trail }) => trail) as Promise<BundleQueryRes>
  }

const errMsg = 'Could not get bundle'

export const useBundle = (_id: string): BundleQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const queryKey = bundleQueryKey(_id)
  const queryFn = bundleQueryFn()
  const res = useQuery(queryKey, queryFn, options)
  return handleRes(res, errMsg)
}

export const refreshBundle = (_id: string) =>
  queryClient.invalidateQueries(bundleQueryKey(_id))
