import { Organization } from '../../../graphql/sdk.generated'
import { useQuery } from 'react-query'
import { handleRes } from '../handle-res'
import { QueryFunction } from 'react-query/types/core/types'
import { sdk } from '../../../../components'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'

export type OrganizationsQueryKey = ReturnType<typeof organizationsQueryKey>
export type OrganizationsQueryRes = Organization[]

export const organizationsQueryKey = () => ['organizations']
export const organizationsQueryFn =
  (): QueryFunction<OrganizationsQueryRes, OrganizationsQueryKey> => () => {
    return sdk
      .organizations()
      .then(
        ({ organizations }) => organizations
      ) as Promise<OrganizationsQueryRes>
  }

const errMsg = 'Could not get organizations'

export const useOrganizations = (): OrganizationsQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const res = useQuery(organizationsQueryKey(), organizationsQueryFn(), options)
  return handleRes(res, errMsg)
}
