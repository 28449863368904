import create from 'zustand/vanilla'
import { createBrowserHistory } from 'history'
import jwtDecode from 'jwt-decode'
import { anonAuthUrl, refreshUrl } from './config'
import * as Sentry from '@sentry/react'
import { Mutex } from 'async-mutex'

export type AccessTokenStoreState = {
  accessToken: string | null
  anonToken: string | null
}

export const accessTokenStore = create<AccessTokenStoreState>(() => ({
  accessToken: null,
  anonToken: null,
}))

export const isTokenValid = (token: string): boolean => {
  try {
    return (
      Boolean(token) &&
      Date.now() < (jwtDecode(token) as { exp: number }).exp * 1000
    )
  } catch (e) {
    console.error(e)
    return false
  }
}

export const verifyRefreshToken = (refreshToken: string | null): boolean =>
  verifyToken(refreshToken, 'refresh')
export const setRefreshToken = (
  refreshToken: string,
  remember: boolean
): void =>
  (remember ? localStorage : sessionStorage).setItem(
    'refreshToken',
    refreshToken
  )
export const getRefreshToken = (): string | null =>
  localStorage.getItem('refreshToken') ?? sessionStorage.getItem('refreshToken')
export const validRefreshToken = (): boolean =>
  verifyRefreshToken(getRefreshToken())

export const verifyAccessToken = (accessToken: string | null): boolean =>
  verifyToken(accessToken, 'access')
export const setAccessToken = (input: string | null): void =>
  accessTokenStore.setState({ accessToken: input })
export const getAccessToken = (): string | null =>
  accessTokenStore.getState().accessToken
export const validAccessToken = (): boolean =>
  verifyAccessToken(getAccessToken())

export const verifyAnonToken = (anonToken: string | null): boolean =>
  verifyToken(anonToken, 'anon')
export const setAnonToken = (input: string | null): void =>
  accessTokenStore.setState({ anonToken: input })
export const getAnonToken = (): string | null =>
  accessTokenStore.getState().anonToken
export const validAnonToken = (): boolean => verifyAnonToken(getAnonToken())

/**
 * @throws {Error}
 */
export const handleCantRefresh = (error: Error): never => {
  console.error(
    'Unable to refresh; Clearing local storage and sending user to login',
    error
  )

  // TODO: Reroute user in such a way that they can resume their previous session where they left off
  localStorage.clear()
  sessionStorage.clear()
  createBrowserHistory().push('/login')
  window.location.reload()
  throw error
}

export const verifyToken = (
  token: string | null,
  tokenType: 'access' | 'refresh' | 'anon'
): boolean => {
  if (!token) {
    console.warn(`No ${tokenType} token found`)
    return false
  }
  if (!isTokenValid(token)) {
    console.warn(
      `${
        tokenType[0].toUpperCase() + tokenType.substr(1)
      } token was expired or otherwise invalid`
    )
    return false
  }
  return true
}

export interface AuthResponse {
  access_token: string
}

const refreshMutex = new Mutex()

export const refreshAccessToken = async (force?: true): Promise<string> => {
  return await refreshMutex.runExclusive(async () => {
    const originalAccessToken = getAccessToken()
    if (!force && verifyAccessToken(originalAccessToken)) {
      console.debug(
        'Access token did not need to be refreshed, it may have been refreshed while this attempt was enqueued'
      )
      return originalAccessToken
    }
    const refreshToken = getRefreshToken()
    if (!verifyRefreshToken(refreshToken)) {
      handleCantRefresh(new Error('Refresh token was not valid'))
    }
    console.info('Refreshing access token...', { force })
    const accessToken = await fetch(refreshUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshToken}`,
      },
    })
      .then(res => res.json())
      .then(body => body.access_token)
      .catch(e => {
        Sentry.captureException(e)
        handleCantRefresh(e)
      })
    console.info('Refreshed access token.')
    return accessToken
  })
}

export const newAnonSession = async (): Promise<string> =>
  await fetch(anonAuthUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json() as Promise<AuthResponse>)
    .then(body => body.access_token)

export const clearSession = (): void => {
  localStorage.clear()
  sessionStorage.clear()
  // updateBranding();
  accessTokenStore.setState(
    {
      accessToken: null,
      anonToken: null,
    },
    true
  )
}
