import React, { VFC } from 'react'
import LoadingCard from '../LoadingCard'
import './loadingList.css'

const LoadingList: VFC = () => (
  <ul className='list-body'>
    {[...Array(5).keys()].map(i => (
      <LoadingCard key={i} />
    ))}
  </ul>
)

export default LoadingList
