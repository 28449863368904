import { FormDataBase } from '../../form'
import { Path, useFormContext } from 'react-hook-form'
import React, { ReactElement } from 'react'
import Input, { FlavorProps, InputLabel, InputProps } from '../Input'

function StringInputFlavor<FD extends FormDataBase>(
  props: FlavorProps<FD, unknown>
): ReactElement {
  const {
    required,
    name,
    label,
    type,
    disabled,
    autocompleteStarted,
    autocompleteCanceled,
    formError,
  } = props
  const { register } = useFormContext<FD>()

  const handleAutoFill: React.AnimationEventHandler<HTMLInputElement> = e => {
    if (e.animationName === 'onAutoFillStart') return autocompleteStarted()
    if (e.animationName === 'onAutoFillCancel') return autocompleteCanceled()
  }

  return (
    <div className='w-full'>
      <div id={'input-row-' + name} className='input-row'>
        <InputLabel {...props} />
        <input
          id={label}
          key={label}
          type={type}
          disabled={disabled}
          onAnimationStart={handleAutoFill}
          {...register(name as Path<FD>, {
            required: required ? 'required' : undefined,
          })}
        />
      </div>
      {formError && <span className='error-message'>{formError.message}</span>}
    </div>
  )
}

const StringInput = <FD extends FormDataBase>(
  props: InputProps<FD>
): ReactElement => <Input<FD> {...props} Flavor={StringInputFlavor} />

export default StringInput
