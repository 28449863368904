import { useParams } from 'react-router-dom'
import { useThing } from '../data'
import { NotFoundError } from '../../../components/core/errors'

export const useSelectedThingId = () => useParams()['thingId']
export const useSelectedThingIdOrThrow = () => {
  const selectedThingId = useSelectedThingId()
  if (!selectedThingId) throw new Error('Could not get thing ID from path')
  return selectedThingId
}

export const useSelectedThing = () => {
  const thingId = useSelectedThingId()
  if (!thingId) return null
  return useThing(thingId)
}

export const useSelectedThingOrThrow = () => {
  const selectedThing = useSelectedThing()
  if (!selectedThing) throw new NotFoundError('Could not get selected thing')
  return selectedThing
}
