import { useQuery } from 'react-query'
import {
  organizationQueryFn,
  organizationQueryKey,
  OrganizationQueryRes,
} from './useOrganization'
import { handleRes } from '../handle-res'
import { useKhh } from '../../../../components'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'

export const useUserOrganizationId = (): string | null => {
  const userId = useKhh().user?.organization?.['$oid']
  return userId ?? null
}

const errMsg = 'Could not get user organization'

export const useUserOrganization = (): OrganizationQueryRes | null => {
  const _id = useUserOrganizationId()
  if (!_id) return null
  const options = {
    onError: recoverableErrorHandler(errMsg),
  }
  const queryKey = organizationQueryKey(_id)
  const queryFn = organizationQueryFn()
  const res = useQuery(queryKey, queryFn, options)
  return handleRes(res, errMsg)
}

export const useUserOrganizationOrThrow = (): OrganizationQueryRes => {
  const organization = useUserOrganization()
  if (!organization) throw new Error('Could not get user organization')
  return organization
}
