import { MutationFunction, useMutation } from 'react-query'
import { sdk } from 'src/components'
import { Nugget } from 'src/core/graphql/graphql.generated'
import { refreshThingsForBundle } from './useThingsForBundle'
import { handleSuccess } from 'src/core/util/handle-success'
import { handleRecoverableError } from 'src/core/util/handle-recoverable-error'
import { useSelectedBundleIdOrThrow } from 'src/core/hooks/params'

export type DeleteThingMutationInput = string
export type DeleteThingMutationRes = Nugget

export const deleteThingMutationFn =
  (_id: DeleteThingMutationInput): MutationFunction<DeleteThingMutationRes> =>
  () => {
    return sdk
      .deleteOneNugget({ query: { _id } })
      .then(
        ({ deleteOneNugget }) => deleteOneNugget
      ) as Promise<DeleteThingMutationRes>
  }

const errMsg = 'Could not delete thing'

export const useDeleteThingMutation = (input: DeleteThingMutationInput) => {
  const selectedBundleId = useSelectedBundleIdOrThrow()
  return useMutation<DeleteThingMutationRes, Error, void, void>(
    deleteThingMutationFn(input),
    {
      onSuccess: () => handleSuccess('Deleted thing'),
      onError: error => handleRecoverableError(errMsg, error),
      onSettled: () => refreshThingsForBundle(selectedBundleId),
    }
  )
}
