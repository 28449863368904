import React, { FC } from 'react'
import './login.scss'
import '../../styles/row.scss'
import {
  FieldErrors,
  FormProvider,
  useForm,
  useFormState,
} from 'react-hook-form'
import EmailDecoration from '../../decorations/EmailDecoration'
import PasswordDecoration from '../../decorations/PasswordDecoration'
import { FormError, FormProps } from '../../form'
import StringInput from '../../components/StringInput'
import Button from '../../components/Button'
import BooleanInput from '../../components/BooleanInput'

export type LoginFormData = {
  username: string
  password: string
  remember: boolean
}

export enum LoginErrorCode {
  invalidUsername = 'invalidUsername',
  invalidPassword = 'invalidPassword',
  networkError = 'networkError',
  unknown = 'unknown',
}

export const isLoginError = (u: unknown): u is LoginFormError =>
  !!(u && typeof u === 'object' && loginErrorCode in u)
export const loginErrorCode: unique symbol = Symbol('loginErrorCode')

export interface LoginFormError extends FormError<LoginFormData> {
  [loginErrorCode]: LoginErrorCode
}

export interface LoginFormProps
  extends FormProps<LoginFormData, LoginFormError> {
  handleLogin: (data: LoginFormData) => Promise<unknown>
  handleForgot: () => void
  loggingIn: boolean
}

type LoginInputsProps = {
  error?: FormError<LoginFormData>
  errors: FieldErrors
  resetError: () => void
}

// Insulate from methods.watch() in main component
const LoginInputs: FC<LoginInputsProps> = React.memo<LoginInputsProps>(
  ({ error, resetError }) => (
    <div className='flex flex-col space-y-4'>
      <StringInput
        name='username'
        type='email'
        label='Email'
        required
        Decoration={EmailDecoration}
        error={error}
        resetError={resetError}
      />

      <StringInput
        name='password'
        type='password'
        required
        Decoration={PasswordDecoration}
        error={error}
        resetError={resetError}
      />
    </div>
  ),
  (prevProps, nextProps) =>
    prevProps.error === nextProps.error && prevProps.errors === nextProps.errors
)
LoginInputs.displayName = 'LoginInputs'

const Login: FC<LoginFormProps> = props => {
  const {
    handleLogin,
    handleForgot,
    loggingIn,
    error,
    resetError,
    initialData,
  } = props
  const opts = initialData ? { defaultValues: initialData } : undefined
  const methods = useForm<LoginFormData>({
    ...opts,
    reValidateMode: 'onChange',
  })
  const onSubmit = methods.handleSubmit(handleLogin)
  const { errors } = useFormState<LoginFormData>({ control: methods.control })

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className={`login-form
    ${!!error || Object.keys(errors).length > 0 ? 'some-error' : ''}
    ${error ? 'login-error' : ''}
    ${Object.keys(errors).length > 0 ? 'validation-error' : ''}`}
      >
        <LoginInputs errors={errors} error={error} resetError={resetError} />

        <div className='spaced-row extras-row relative'>
          <BooleanInput name='remember' label='Remember Me' error={error} />
          <p className='forgot' onClick={handleForgot}>
            Forgot Password
          </p>
          <div className='line-flourish'>
            <div />
          </div>
        </div>

        <p className='form-error'>
          {error && (error?.displayMessage || 'An error occurred')}
        </p>
        <Button
          label={'Sign In'}
          rank='pane'
          type='submit'
          isLoading={loggingIn}
          className='border-none'
        />
      </form>
    </FormProvider>
  )
}

export interface RawLoginError extends Error {
  statusCode: number
  error: string
}

// https://developer.mongodb.com/community/forums/t/how-could-i-know-all-about-error-codes/110634/3?u=andrew_kaiser
export const transformLoginError = (
  rawError: RawLoginError
): LoginFormError => {
  const { statusCode, error } = rawError
  let errorCode: LoginErrorCode
  switch (error || statusCode) {
    case 'invalid username':
      errorCode = LoginErrorCode.invalidUsername
      break
    case 'invalid username/password':
    case 'invalid password':
    case 401:
      errorCode = LoginErrorCode.invalidPassword
      break
    default:
      errorCode = LoginErrorCode.unknown
      break
  }
  const messages: Record<LoginErrorCode, string> = {
    [LoginErrorCode.invalidUsername]: 'Email is invalid',
    [LoginErrorCode.invalidPassword]: 'Invalid password',
    [LoginErrorCode.networkError]: 'Network error',
    [LoginErrorCode.unknown]: 'An unknown error occurred',
  }
  const displayMessage = messages[errorCode]
  console.error(rawError)
  return { ...rawError, [loginErrorCode]: errorCode, displayMessage }
}

export default Login
