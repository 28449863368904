import { useQuery } from 'react-query'
import { Trail } from '../../../graphql/sdk.generated'
import { handleRes } from '../handle-res'
import { queryClient, sdk } from '../../../../components'
import { byOrder } from '../../../util/sorting'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'
import { QueryFunction } from 'react-query/types/core/types'

export type BundlesForOrgQueryRes = Trail[]
export type BundlesForOrgQueryKey = ReturnType<typeof bundlesForOrgQueryKey>

const bundlesForOrgQueryKey = (organization: string) => [
  'bundlesForOrg',
  organization,
]
const bundlesQueryFn =
  (): QueryFunction<BundlesForOrgQueryRes, BundlesForOrgQueryKey> =>
  ({ queryKey: [, organization] }): Promise<BundlesForOrgQueryRes> => {
    return sdk
      .trails({ query: { organization } })
      .then(({ trails }) => trails as Trail[])
      .then(trails => trails.sort(byOrder))
  }

const errMsg = 'Could not get bundles for organization'

export const useBundlesForOrg = (
  organization: string
): BundlesForOrgQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const queryKey = bundlesForOrgQueryKey(organization)
  const res = useQuery(queryKey, bundlesQueryFn(), options)
  return handleRes(res, errMsg)
}

export const refreshBundlesForOrg = (organization: string) =>
  queryClient.invalidateQueries(bundlesForOrgQueryKey(organization))
