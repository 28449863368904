import React, { VFC } from 'react'

const CheckFrame: VFC = () => (
  <svg
    className='w-4 h-4'
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M2 0Q0 0 0 2L0 18Q0 20 2 20L18 20Q20 20 20 18L20 2Q20 0 18 0L2 0M3 2 17 2Q18 2 18 3L18 17Q18 18 17 18L3 18Q2 18 2 17L2 3Q2 2 3 2'
      clipRule='evenodd'
    />
  </svg>
)

export default CheckFrame
