import React, { FC, useEffect, useState } from 'react'
import './loading.css'
import AnimatedLogo from '../AnimatedLogo'

const Loading: FC<{
  size?: number
  bg?: string
  small?: true
}> = ({ size = 96, bg, small }) => {
  const [frame, setFrame] = useState<number>(0)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setFrame(1)
    }, 50)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => setFrame((frame + 1) % 4), 400)
    return () => clearInterval(interval)
  })

  return (
    <div
      className={!small ? 'flex w-full h-full justify-center items-center' : ''}
    >
      <div
        className={`loading frame-${frame}`}
        style={{ width: size, height: size, background: bg }}
      >
        <AnimatedLogo />
      </div>
    </div>
  )
}

export default Loading
