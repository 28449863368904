import React, { Suspense, VFC } from 'react'
import SelectorInput from './SelectorInput'
import { useOrganizations } from '../../core/hooks/data'

export const OrganizationSelectorFormInput: VFC = () => {
  return (
    <Suspense fallback={<LoadingOrganizationSelectorFormInput />}>
      <OrganizationSelectorFormInputInner />
    </Suspense>
  )
}

const LoadingOrganizationSelectorFormInput: VFC = () => <>Loading...</>

const OrganizationSelectorFormInputInner: VFC = () => (
  <SelectorInput<{ organization?: string }>
    options={useOrganizations().map(org => ({
      label: org.name,
      value: org._id,
    }))}
    label='Organization'
    name='organization'
    type='text'
  />
)
