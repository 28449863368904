import {
  Trail,
  UpdateOneTrailMutationVariables,
} from '../../../graphql/sdk.generated'
import { MutationFunction, useMutation } from 'react-query'
import { bundleQueryKey, BundleQueryRes } from './useBundle'
import { refreshBundlesForOrg } from './useBundlesForOrg'
import { queryClient, sdk, useSelectedOrgOrThrow } from 'src/components'
import { handleSuccess } from '../../../util/handle-success'
import { handleRecoverableError } from '../../../util/handle-recoverable-error'

export type UpdateBundleMutationInput = UpdateOneTrailMutationVariables['set']
export type UpdateBundleMutationRes = Trail
export const updateBundleMutationFn = (
  _id: string
): MutationFunction<UpdateBundleMutationRes, UpdateBundleMutationInput> => {
  return set => {
    return sdk
      .updateOneTrail({ query: { _id }, set })
      .then(
        ({ updateOneTrail }) => updateOneTrail
      ) as Promise<UpdateBundleMutationRes>
  }
}

const errMsg = 'Could not update bundle'

export const useUpdateBundleMutation = (_id: string) => {
  const selectedOrg = useSelectedOrgOrThrow()
  return useMutation<
    UpdateBundleMutationRes,
    Error,
    UpdateBundleMutationInput,
    void
  >(updateBundleMutationFn(_id), {
    onSuccess: data => {
      handleSuccess('Updated bundle')
      queryClient.setQueryData<BundleQueryRes>(bundleQueryKey(_id), data)
      refreshBundlesForOrg(selectedOrg._id).then() // Dangle
    },
    onError: error => handleRecoverableError(errMsg, error),
    onSettled: () => queryClient.invalidateQueries(bundleQueryKey(_id)).then(),
  })
}
