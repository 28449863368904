import React, { VFC } from 'react'

const Check: VFC = () => (
  <svg
    className='w-4 h-4'
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M 16.707 5.293 a 1 1 0 0 1 0 1.414 l -8 8 a 1 1 0 0 1 -1.414 0 l -4 -4 a 1 1 0 0 1 1.414 -1.414 L 8 12.586 l 7.293 -7.293 a 1 1 0 0 1 1.414 0 z M 2 0 Q 0 0 0 2 L 0 18 Q 0 20 2 20 L 18 20 Q 20 20 20 18 L 20 2 Q 20 0 18 0 L 2 0'
      clipRule='evenodd'
    />
  </svg>
)

export default Check
