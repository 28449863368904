import React, { VFC } from 'react'
import Div100vh from 'react-div-100vh'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ErrorComponent, queryClient } from 'src/components'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import SectionBoundary from './components/components/KhhErrorBoundary'

const AppLoadable = React.lazy(() => import('./AppLoadable'))
const App: VFC = () => (
  <Div100vh className='relative' id='app'>
    <ErrorBoundary fallback={ErrorComponent}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <SectionBoundary>
            <AppLoadable />
          </SectionBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  </Div100vh>
)

export default App
