import React, { VFC } from 'react'

const AnimatedLogo: VFC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95.4 95.4'>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <polygon
            className='logo-tri tri-1'
            points='95.4 0 0 0 47.7 47.7 95.4 0'
          />
          <polygon
            className='logo-tri tri-2'
            points='0 95.4 95.4 95.4 47.7 47.7 0 95.4'
          />
          <polygon
            className='logo-tri tri-3'
            points='0 0 0 95.4 0 95.4 47.7 47.7 0 0'
          />
        </g>
      </g>
    </svg>
  )
}

export default AnimatedLogo
