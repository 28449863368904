import { useParams } from 'react-router-dom'
import { useUser } from '../data'
import { NotFoundError } from '../../../components/core/errors'

export const useSelectedUserId = () => useParams()['userId']
export const useSelectedUserIdOrThrow = () => {
  const selectedUserId = useSelectedUserId()
  if (!selectedUserId) throw new Error('Could not get user ID from path')
  return selectedUserId
}

export const useSelectedUser = () => {
  const userId = useSelectedUserId()
  if (!userId) return null
  return useUser(userId)
}

export const useSelectedUserOrThrow = () => {
  const selectedUser = useSelectedUser()
  if (!selectedUser) throw new NotFoundError('Could not get selected user')
  return selectedUser
}
