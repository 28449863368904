import { Modal } from './Modal'
import { OrganizationSelectorFormInput } from './OrganizationSelectorFormInput'
import React, { Suspense, useEffect } from 'react'
import create from 'zustand'
import * as Sentry from '@sentry/react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { Organization } from '../core/graphql/sdk.generated'
import Button from './Button'
import * as R from 'ramda'
import { Loading } from 'src/components'
import { NotFoundError } from '../core/errors'
import { useOrganizations, useUserOrganization } from '../../core/hooks/data'
import { updateBranding } from '../core/branding'

type OrganizationSwitcherState = {
  openOrgSwitcher: () => void
  closeOrgSwitcher: () => void
  clearSelectedOrg: () => void
  setSelectedOrg: (org: Organization) => void
  selectedOrg: Organization | undefined
  isOpen: boolean
}
export const useOrgSwitcherState = create<OrganizationSwitcherState>(set => ({
  openOrgSwitcher: () => set({ isOpen: true }),
  closeOrgSwitcher: () => set({ isOpen: false }),
  clearSelectedOrg: () => set({ selectedOrg: undefined }),
  setSelectedOrg: (selectedOrg: Organization) => set({ selectedOrg }),
  selectedOrg: undefined,
  isOpen: false,
}))
// FIXME: Change selectedOrg in switcher state to an ID, use useOrganization query to get object
export const useSelectedOrg = (): Organization | null => {
  const selected = useOrgSwitcherState(({ selectedOrg }) => selectedOrg)
  const userOrg = useUserOrganization()
  const org = selected ?? userOrg
  useEffect(() => updateBranding(org ?? undefined), [org])
  return org
}
export const useSelectedOrgOrThrow = (): Organization => {
  const selectedOrg = useSelectedOrg()
  if (!selectedOrg) throw new NotFoundError('Could not get selected org')
  return selectedOrg
}
export const useOpenOrgSwitcher = () =>
  useOrgSwitcherState(({ openOrgSwitcher }) => openOrgSwitcher)
const useSetSelectedOrg = () =>
  useOrgSwitcherState(({ setSelectedOrg }) => setSelectedOrg)
const useCloseOrgSwitcher = () =>
  useOrgSwitcherState(({ closeOrgSwitcher }) => closeOrgSwitcher)

const useOrgSwitcherFormSubmit = (
  form: UseFormReturn<OrganizationSwitcherFormData>
) => {
  const setSelectedOrg = useSetSelectedOrg()
  const closeOrgSwitcher = useCloseOrgSwitcher()
  const organizations = useOrganizations()
  return form.handleSubmit(({ organization: orgId }) => {
    const selectedOrg = organizations.find(R.propEq('_id', orgId))
    if (!selectedOrg)
      throw new Error('Could not find organization with ID: ' + orgId)
    setSelectedOrg(selectedOrg)
    closeOrgSwitcher()
  })
}

type OrganizationSwitcherFormData = {
  organization: Organization
}
export const OrganizationSwitcherModal = () => {
  const { isOpen, closeOrgSwitcher } = useOrgSwitcherState()
  return (
    <Sentry.ErrorBoundary onError={closeOrgSwitcher}>
      <Modal isOpen={isOpen}>
        <Suspense fallback={<Loading />}>
          <OrganizationSwitcherModalInner />
        </Suspense>
      </Modal>
    </Sentry.ErrorBoundary>
  )
}

const OrganizationSwitcherModalInner = () => {
  const form = useForm<OrganizationSwitcherFormData>()
  const handleSubmit = useOrgSwitcherFormSubmit(form)
  const { closeOrgSwitcher } = useOrgSwitcherState()

  return (
    <FormProvider {...form}>
      <form className='flex flex-col gap-4' onSubmit={handleSubmit}>
        <Modal.Title title='Select Organization' />

        <OrganizationSelectorFormInput />
        <div className='flex gap-4 justify-between w-full mt-8'>
          <Button label='Cancel' onClick={closeOrgSwitcher} rank='secondary' />
          <Button label='Submit' type='submit' rank='primary' />
        </div>
      </form>
    </FormProvider>
  )
}
