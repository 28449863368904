import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'

export const handleRecoverableError = (
  message: string,
  error?: unknown | Error
) => {
  toast.error(message)
  console.error(message, error)
  Sentry.captureException(error)
}

export const recoverableErrorHandler =
  (message: string) => (error?: unknown | Error) =>
    handleRecoverableError(message, error)
