import { FormDataBase } from '../../form'
import React, { FC, ReactElement, Suspense, VFC } from 'react'
import type { SelectorInputProps } from './loadable'
import './selector.scss'

const LoadingSelectorInput: VFC = () => <div className='loading-selector' />

const SelectorInput = <FD extends FormDataBase>(
  props: SelectorInputProps<FD>
): ReactElement => {
  const LoadedSelectorInput = React.lazy<FC<SelectorInputProps<FD>>>(
    () => import('./loadable')
  )
  return (
    <Suspense fallback={<LoadingSelectorInput />}>
      <LoadedSelectorInput {...props} />
    </Suspense>
  )
}

export default SelectorInput
