import './DurationInput.scss'
import React, { ReactElement, useState } from 'react'
import { FormDataBase } from '../../form'
import Input, { FlavorProps, InputLabel, InputProps } from '../Input'
import { Controller, Path, useFormContext, useWatch } from 'react-hook-form'

export type DurationInputProps<FD extends FormDataBase> = Omit<
  InputProps<FD>,
  'type'
>

const secondsInMinute = 60
const secondsInHour = 60 * secondsInMinute
const secondsInDay = 24 * secondsInHour
const secondsInWeek = 7 * secondsInDay

const weeksAndDaysFromSeconds = (
  seconds: number
): [number | undefined, number | undefined] => {
  const weeks = Math.floor(seconds / secondsInWeek) || undefined
  const days = Math.floor((seconds % secondsInWeek) / secondsInDay) || undefined
  return [weeks, days]
}

const secondsFromWeeksAndDays = (weeks: number, days: number): number =>
  weeks * secondsInWeek + days * secondsInDay

const hintFromValue = (value: number | null | undefined): string => {
  if (value === null) return 'Invalid value'
  if (value === undefined || value === 0)
    return 'Ex. "2 weeks; 3 days" or leave empty'
  return `${secondsToValueString(value)} (${value} total seconds)`
}

export const secondsToValueString = (
  value: number | null
): string | undefined => {
  if (value) {
    const [weeks, days] = weeksAndDaysFromSeconds(value)
    const weekStr = weeks && `${weeks} week${weeks !== 1 ? 's' : ''}`
    const dayStr = days && `${days} day${days !== 1 ? 's' : ''}`
    return [weekStr, dayStr].filter(Boolean).join('; ')
  }
  return undefined
}

// Undefined if no value is passed (null or empty string), null if value is an
// invalid string
const valueStringToSeconds = (
  valueString: string | null | undefined
): number | null | undefined => {
  if (!valueString) return undefined
  if (valueString === 'none') return undefined

  const weekMatch = /([0-9]+)\s*weeks?/[Symbol.match](valueString)
  const dayMatch = /([0-9]+)\s*days?/[Symbol.match](valueString)
  if (!weekMatch && !dayMatch) return null

  const weeks = weekMatch ? parseInt(weekMatch[1]) : 0
  const days = dayMatch ? parseInt(dayMatch[1]) : 0
  return secondsFromWeeksAndDays(weeks, days)
}

function DurationInputFlavor<FD extends FormDataBase>(
  props: FlavorProps<FD, unknown>
): ReactElement {
  const { required, name, label, type, disabled } = props
  const { control, getValues } = useFormContext<FD>()
  const defaultValue = getValues(name) as number | null
  const checkedDefaultValue: number | null =
    typeof defaultValue === 'number' ? defaultValue : null
  const [valueString, setValueString] = useState<string | undefined>(
    secondsToValueString(checkedDefaultValue)
  )
  const isInvalidValueString = valueStringToSeconds(valueString) === null

  return (
    <div className={`duration-input ${isInvalidValueString ? 'error' : ''}`}>
      <div id={'input-row-' + name} className='input-row'>
        <InputLabel {...props} touched={Boolean(valueString)} />
        <Controller
          control={control}
          name={name as Path<FD>}
          rules={{
            required: required ? 'required' : undefined,
            validate: {
              valid: () => !isInvalidValueString,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <input
              id={name}
              key={label}
              type={type}
              name={name}
              disabled={disabled}
              value={valueString}
              onChange={e => {
                const newValue = e.target.value
                setValueString(newValue)
                const seconds = valueStringToSeconds(newValue)
                if (value !== seconds) onChange(seconds ?? null)
              }}
            />
          )}
        />
      </div>
      <DurationInputHint valueString={valueString} {...props} />
    </div>
  )
}

function DurationInputHint<FD extends FormDataBase>(
  props: DurationInputProps<FD> & { valueString: string | undefined }
) {
  const { name, valueString } = props
  const { control } = useFormContext()
  const value = useWatch({ control, name })
  return (
    <p id={`${name}-hint`} className='text-gray-500 text-sm'>
      {hintFromValue(
        valueStringToSeconds(valueString) ??
          ((value ?? undefined) as number | undefined)
      )}
    </p>
  )
}

function DurationInput<FD extends FormDataBase>(
  props: DurationInputProps<FD>
): ReactElement {
  return <Input<FD> {...props} type='string' Flavor={DurationInputFlavor} />
}

export default DurationInput
