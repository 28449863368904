import { GraphQLClient } from 'graphql-request'
import { getSdk, SdkFunctionWrapper } from './sdk.generated'
import polly from 'polly-js'
import { gqlUri } from '../config'
import {
  accessTokenStore,
  getAccessToken,
  getAnonToken,
  handleCantRefresh,
  newAnonSession,
  refreshAccessToken,
  setAccessToken,
  setAnonToken,
  validAccessToken,
  validRefreshToken,
  verifyAnonToken,
} from '../tokens'

export const requestClient = new GraphQLClient(gqlUri, {
  headers: { Authorization: `Bearer ${getAccessToken()}` },
})
export const anonClient = new GraphQLClient(gqlUri, {
  headers: { Authorization: `Bearer ${getAnonToken()}` },
})

accessTokenStore.subscribe(
  accessToken =>
    requestClient.setHeader('Authorization', `Bearer ${accessToken}`),
  ({ accessToken }) => accessToken
)
accessTokenStore.subscribe(
  anonToken => anonClient.setHeader('Authorization', `Bearer ${anonToken}`),
  ({ anonToken }) => anonToken
)

// Exported for testing
export const retryAction = <T>(action: () => Promise<T>) =>
  polly()
    .handle((err: Error) => {
      console.error('GraphqlClient:NetworkError', err)
      return err.message.includes('connect ETIMEDOUT')
    })
    .waitAndRetry(3)
    .executeForPromise(info => {
      if (info.count === 3) {
        console.error('GraphqlClient:MaxRetries', null, {
          ...info,
          action: action.toString(),
        })
      } else if (info.count > 0) {
        console.warn('GraphqlClient:RetryingCall', null, {
          ...info,
          action: action.toString(),
        })
      }

      return action()
    })

// Exported for testing
export const retryWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
) => {
  if (!validAccessToken()) {
    console.warn('Access token was not valid')

    if (!validRefreshToken()) {
      const e = new Error('No refresh token to refresh access token with')
      handleCantRefresh(e)
      throw e
    }

    // ! is fine because we would have thrown above otherwise
    try {
      const accessToken = await refreshAccessToken()
      setAccessToken(accessToken)
    } catch (e: any) {
      handleCantRefresh(e)
      throw e
    }
  }

  return retryAction(action)
}

const anonRetryWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
) => {
  if (!verifyAnonToken(getAnonToken())) {
    console.warn('Anon token was not valid')
    setAnonToken(await newAnonSession())
  }

  return retryAction(action)
}

export const sdk = getSdk(requestClient, retryWrapper)
export const anonSdk = getSdk(anonClient, anonRetryWrapper)
