import Card, { CardItem } from '../Card'
import React, { ReactElement } from 'react'
import { ListProps } from '../List'
import Placeholder from '../Placeholder'

function InlineList<T extends CardItem>(
  props: ListProps<T>
): ReactElement<ListProps<T>> {
  const { items, selected, highlighted, onClick, goToUrl, placeholder } = props

  return items.length === 0 ? (
    <Placeholder>{placeholder}</Placeholder>
  ) : (
    <ul className='list-body'>
      {items.map((item: T) => {
        const isSelected = selected !== undefined && selected._id === item._id
        return (
          <Card<T>
            key={item._id}
            cardTag='list'
            {...{
              onClick,
              goToUrl,
              item,
              isSelected,
              isHighlighted: highlighted?._id === item._id,
            }}
          />
        )
      })}
    </ul>
  )
}

export default InlineList
