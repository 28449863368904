export const throwIfUndefined = <T>(
  tOrNone: T | undefined,
  errMsg: string
): T => {
  if (tOrNone === undefined) throw new Error(errMsg)
  return tOrNone
}

export const handleRes = <T>(
  res: { data: T | undefined; error?: unknown },
  errMsg: string
) => {
  const { data, error } = res
  if (error) throw error
  return throwIfUndefined(data, errMsg)
}
