import ReactModal from 'react-modal'
import React, { ReactElement, ReactNode } from 'react'
import { QueryClientProvider } from 'react-query'
import useKhh, { KhhContextProvider } from '../core/hooks/useKhh'
import { queryClient } from '../../query-client'

export const Modal = (props: {
  isOpen: boolean
  children?: ReactNode
}): ReactElement => {
  const { isOpen, children } = props
  const appEl = document.getElementById('app')!
  const khh = useKhh()

  return (
    <ReactModal
      isOpen={isOpen}
      className='absolute rounded-xl inset-0 flex justify-center items-center z-50'
      appElement={appEl}
      style={{ overlay: { zIndex: 10000 } }}
    >
      <KhhContextProvider khh={khh}>
        <QueryClientProvider client={queryClient}>
          <div className='flex flex-col text-center justify-center bg-white shadow-2xl rounded-xl p-8 z-50'>
            {children}
          </div>
        </QueryClientProvider>
      </KhhContextProvider>
    </ReactModal>
  )
}

Modal.Title = ({ title }: { title: string }): ReactElement => (
  <span className='text-2xl mb-2'>{title}</span>
)
Modal.Subtitle = ({ subtitle }: { subtitle: string }): ReactElement => (
  <span className='text-lg font-light text-gray-600 mb-8'>{subtitle}</span>
)
