import { MutationFunction, useMutation } from 'react-query'
import {
  queryClient,
  sdk,
  UpdateOneUserMutationVariables,
  User,
} from '../../../../components'
import { handleSuccess } from '../../../util/handle-success'
import { refreshUsersForOrg, userQueryKey, UserQueryRes } from '../users'
import { handleRecoverableError } from '../../../util/handle-recoverable-error'

export type UpdateUserMutationInput = UpdateOneUserMutationVariables['set']
export type UpdateUserMutationRes = User
export const updateUserMutationFn = (
  _id: string
): MutationFunction<UpdateUserMutationRes, UpdateUserMutationInput> => {
  return set => {
    return sdk
      .updateOneUser({ query: { _id }, set })
      .then(
        ({ updateOneUser }) => updateOneUser
      ) as Promise<UpdateUserMutationRes>
  }
}

const errMsg = 'Could not update user'

export const useUpdateUserMutation = (_id: string) => {
  return useMutation<
    UpdateUserMutationRes,
    Error,
    UpdateUserMutationInput,
    void
  >(updateUserMutationFn(_id), {
    onSuccess: data => {
      handleSuccess('Updated user')
      queryClient.setQueryData<UserQueryRes>(userQueryKey(_id), data)
      refreshUsersForOrg(data.organization).then() // Dangle
    },
    onError: error => handleRecoverableError(errMsg, error),
    onSettled: () => queryClient.invalidateQueries(userQueryKey(_id)).then(),
  })
}
