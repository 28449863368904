import React, { FC, ReactElement, useEffect, useState, VFC } from 'react'
import {
  FieldError,
  FieldPath,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import './input.scss'
import { motion } from 'framer-motion'
import { FormDataBase, FormError } from '../../form'

export type InputProps<FormData extends FormDataBase> = {
  name: FieldPath<FormData>
  label?: string
  disabled?: boolean
  type: HTMLInputElement['type']
  Decoration?: VFC
  error?: FormError<FormData>
  resetError?: () => void
  required?: boolean
  canBeDirtied?: true
  noLabelOffset?: true
}

export interface InputState {
  error: boolean
  requiredError: boolean
  formError?: FieldError | undefined
  invalid: boolean
  touched: boolean
}

export interface FlavorCallbacks {
  wasTouched: (empty?: boolean) => void
  autocompleteStarted: () => void
  autocompleteCanceled: () => void
}

export function InputLabel<FormData extends FormDataBase>(
  props: Pick<
    InputProps<FormData>,
    | 'Decoration'
    | 'name'
    | 'required'
    | 'label'
    | 'canBeDirtied'
    | 'noLabelOffset'
  > &
    Pick<InputState, 'invalid' | 'touched' | 'requiredError'>
): ReactElement {
  const {
    Decoration,
    name,
    requiredError,
    label,
    invalid,
    touched,
    canBeDirtied,
    noLabelOffset,
  } = props

  /*Default to field name if no explicit label provided*/
  const defaultedLabel = label ?? name[0].toUpperCase() + name.substring(1)

  return (
    <label
      className={`
      ${touched ? 'touched' : ''} 
      ${canBeDirtied ? 'can-be-dirtied' : ''}
      ${noLabelOffset ? 'no-offset' : ''}
      `}
    >
      {Decoration && (
        <motion.div
          initial={false}
          animate={
            !touched
              ? { width: '1rem', marginRight: '0.75rem', opacity: 1 }
              : { width: '0rem', marginRight: '0rem', opacity: 0 }
          }
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <Decoration />
        </motion.div>
      )}
      {defaultedLabel}
      {requiredError && <span>&nbsp;- Required</span>}
      {invalid && <span>&nbsp;- Invalid</span>}
    </label>
  )
}

export type FlavorProps<
  FormData extends FormDataBase,
  ExtraFlavorProps = Record<string, never>
> = InputProps<FormData> & ExtraFlavorProps & InputState & FlavorCallbacks
export type InputFlavor<
  FormData extends FormDataBase,
  ExtraFlavorProps = Record<string, never>
> = FC<FlavorProps<FormData, ExtraFlavorProps>>

type ValueTypes = string | number | boolean | null | undefined
export function Input<
  FormData extends FormDataBase,
  ExtraFlavorProps extends InputProps<FormData> = InputProps<FormData>
>(
  props: ExtraFlavorProps & { Flavor: InputFlavor<FormData, ExtraFlavorProps> }
): ReactElement {
  const {
    name,
    disabled = false,
    error: submitError,
    resetError,
    Flavor,
  } = props

  const { control, formState } = useFormContext<FormData>()
  const formError: FieldError | undefined = formState.errors[name] as
    | FieldError
    | undefined

  const value = useWatch<FormData>({ control, name }) as ValueTypes

  const [touched, setTouched] = useState<boolean>(!!value)
  const dirty = formState.dirtyFields[name]

  const wasTouched = () => setTouched(!!value && value !== '')
  const autocompleteStarted = () => setTouched(true)
  const autocompleteCanceled = () => {
    if (!value || value === '') setTouched(false)
  }
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (firstRender) return setFirstRender(false)
    if (typeof resetError === 'function') resetError()
    wasTouched()
  }, [value])

  const requiredError = formError?.type === 'required'

  const error = !!submitError || !!formError
  const invalid = submitError?.field === name

  const flavorProps: FlavorCallbacks = {
    wasTouched,
    autocompleteStarted,
    autocompleteCanceled,
  }
  const inputState: InputState = {
    touched,
    error,
    invalid,
    formError,
    requiredError,
  }
  const className = `input-container 
    ${error ? 'error' : ''} 
    ${touched ? 'touched' : ''}
    ${disabled ? 'disabled' : 'enabled'}
    ${dirty ? 'dirty' : ''}`

  return (
    <div className={className} data-name={name}>
      <Flavor {...props} {...flavorProps} {...inputState} />
    </div>
  )
}

export default Input
