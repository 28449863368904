import React, { VFC } from 'react'
import { motion } from 'framer-motion'
import './loadingCard.css'

const LoadingLine = () => {
  const style = {
    '--line-width': Math.random() * 50 + 50 + '%',
  } as React.CSSProperties
  return <div className='loading-card-line shimmer' style={style} />
}

const LoadingCard: VFC = () => {
  return (
    <motion.div className='loading-card shadow-md'>
      <div className='loading-card-text space-y-2'>
        <LoadingLine />
        <LoadingLine />
      </div>
    </motion.div>
  )
}

export default LoadingCard
