import { useQuery } from 'react-query'
import { User } from '../../../graphql/sdk.generated'
import { handleRes } from '../handle-res'
import { queryClient, sdk } from '../../../../components'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'
import { QueryFunction } from 'react-query/types/core/types'

export type UsersForOrgQueryRes = User[]
export type UsersForOrgQueryKey = ReturnType<typeof usersForOrgQueryKey>

export const usersForOrgQueryKey = (orgId: string) => ['usersForOrg', orgId]
export const usersForOrgQueryFn =
  (): QueryFunction<UsersForOrgQueryRes, UsersForOrgQueryKey> =>
  ({ queryKey: [, organization] }): Promise<UsersForOrgQueryRes> => {
    return sdk
      .users({ query: { organization } })
      .then(({ users }) => users as User[])
  }

const errMsg = 'Could not get users for organization'

export const useUsersForOrg = (orgId: string): UsersForOrgQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const queryKey = usersForOrgQueryKey(orgId)
  const res = useQuery(queryKey, usersForOrgQueryFn(), options)
  return handleRes(res, errMsg)
}

export const refreshUsersForOrg = (orgId: string) => {
  return queryClient.invalidateQueries('usersForOrg')
}
