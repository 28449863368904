import React, { createContext, FC, Reducer, useEffect, useReducer } from 'react'
import * as RealmWeb from 'realm-web'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  accessTokenStore,
  clearSession,
  refreshAccessToken,
  setAccessToken,
  setRefreshToken,
  validAccessToken,
  validRefreshToken,
} from '../tokens'
import { Organization, User } from '../graphql/types.generated'
import { sdk } from '../graphql/requestClient'
import Loading from '../../components/Loading'
import { updateBranding } from '../branding'
import {
  organizationsQueryFn,
  organizationsQueryKey,
  organizationQueryKey,
} from 'src/core/hooks/data'
import { queryClient } from '../../../query-client'

const REALM_APP_ID = process.env['REACT_APP_REALM_APP_ID']
if (!REALM_APP_ID) throw new Error('Could not get app ID')
const app = new RealmWeb.App({ id: REALM_APP_ID })

type AuthReducerState = {
  isAuthenticated: boolean
  organization: Organization | null
  user: User | null
  loaded: boolean | Error
}
const initState = {
  isAuthenticated: false,
  user: null,
  organization: null,
  loaded: false,
}
type Details = Required<Pick<AuthReducerState, 'user' | 'organization'>>

type AuthReducerAction =
  | { type: 'loaded'; payload: AuthReducerState }
  | { type: 'login'; payload: Details }
  | { type: 'refresh'; payload: Details }
  | { type: 'logout' }

const authReducer: Reducer<AuthReducerState, AuthReducerAction> = (
  state: AuthReducerState,
  action: AuthReducerAction
) => {
  switch (action.type) {
    case 'loaded':
      return action.payload
    case 'login':
      return { ...state, isAuthenticated: true, ...action.payload }
    case 'refresh':
      return { ...state, isAuthenticated: true, ...action.payload }
    case 'logout':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        organization: null,
      }
    default:
      return state
  }
}

export type KhhContext = AuthReducerState & {
  id: string
  registerUser: (email: string, password: string) => Promise<void>
  initiateResetPassword: (email: string) => Promise<void>
  confirmResetPassword: (
    token: string,
    tokenId: string,
    newPassword: string
  ) => Promise<void>
  refresh: () => Promise<void>
  logIn: (email: string, pass: string, remember: boolean) => Promise<void>
  logOut: () => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Default is unused
// @ts-ignore
export const KhhContext = createContext<KhhContext>({})

const handleDetailsChange = async (
  accessToken: string
): Promise<Details | null> => {
  if (!accessToken) return null
  const { user_data: user } = jwtDecode<{ user_data: User }>(accessToken)

  if (!user) {
    console.info('User had no details')
    return null
  }

  localStorage.setItem('user', JSON.stringify(user))
  let organization: Organization
  try {
    const orgQuery = await sdk.organization({
      query: { _id: user.organization.$oid },
    })
    if (!orgQuery.organization)
      throw new Error(
        `Could not get organization with ID ${user.organization.$oid}`
      )
    organization = orgQuery.organization
  } catch (e) {
    Sentry.captureException(e)
    throw e
  }

  localStorage.setItem('organization', JSON.stringify(organization))
  updateBranding(organization)

  return { user, organization }
}

export type KhhContextProps =
  | {
      resetClient?: () => void
      testingState?: AuthReducerState
    }
  | {
      khh: KhhContext
    }
export const KhhContextProvider: FC<KhhContextProps> = props => {
  if ('khh' in props)
    return (
      <KhhContext.Provider value={props.khh}>
        {props.children}
      </KhhContext.Provider>
    )

  const { children, testingState, resetClient } = props
  const [state, dispatch] = useReducer<
    Reducer<AuthReducerState, AuthReducerAction>
  >(authReducer, testingState ?? initState)
  const navigate = useNavigate()

  const handleAccessTokenChange = async (accessToken: string | null) => {
    console.debug('Access token was updated', !!accessToken)
    if (!accessToken) {
      dispatch({ type: 'logout' })
      return
    }

    const details = await handleDetailsChange(accessToken)
    if (!details) {
      console.debug(
        'Redirecting to join from RealmContext access token subscription'
      )
      return navigate('/join')
    }

    if (resetClient) resetClient()

    dispatch({
      type: 'login',
      payload: details,
    })
  }

  // On first render of RealmContext, initialize and add hook to handle updates to access token
  useEffect(() => {
    const handleInitialRefresh = async (accessToken: string) => {
      setAccessToken(accessToken)
      const details = await handleDetailsChange(accessToken)

      if (details === null) {
        navigate('/join')
        dispatch({
          type: 'loaded',
          payload: {
            user: null,
            organization: null,
            loaded: true,
            isAuthenticated: true,
          },
        })
        return
      }

      dispatch({
        type: 'loaded',
        payload: { ...details, loaded: true, isAuthenticated: true },
      })
    }

    const unsub = accessTokenStore.subscribe(
      handleAccessTokenChange,
      ({ accessToken }) => accessToken
    )

    // If we aren't in production, check if we need to grab cypressToken for E2E tests
    if (process.env.NODE_ENV !== 'production') {
      // Skip all this if we were given a testing state
      if (testingState) return

      // If access token is injected by Cypress
      const cypressToken = localStorage.getItem('cypressToken')
      if (cypressToken) setAccessToken(cypressToken)
    }

    // FIXME: updateBranding on refresh always uses user org, not selected org
    const userInStorage = localStorage.getItem('user')
    const orgInStorage = localStorage.getItem('organization')

    const isAuthenticated = validAccessToken() || validRefreshToken()
    let user = null,
      organization = null

    if (validRefreshToken() && !validAccessToken()) {
      console.debug(
        'useKhh found valid refresh token but not valid access token'
      )
      refreshAccessToken().then(handleInitialRefresh)
      return unsub
    }

    if (!isAuthenticated) {
      console.debug('User was not authenticated')
      dispatch({
        type: 'loaded',
        payload: { user, organization, isAuthenticated, loaded: true },
      })
      return unsub
    }

    if (!userInStorage || !orgInStorage) {
      if (!userInStorage) console.warn('Found refresh token but not user')
      if (!orgInStorage)
        console.warn('Found refresh token but not organization')
      console.warn('Clearing local storage to attempt to solve issue...')
      localStorage.clear()
      dispatch({
        type: 'loaded',
        payload: { user, organization, isAuthenticated, loaded: true },
      })
      return unsub
    }

    user = JSON.parse(userInStorage)
    organization = JSON.parse(orgInStorage)
    updateBranding(organization)

    // Set user organization, so we don't need to query for it later
    queryClient.setQueryData(
      organizationQueryKey(organization._id),
      organization
    )

    dispatch({
      type: 'loaded',
      payload: { user, organization, isAuthenticated, loaded: true },
    })

    return unsub
    // eslint-disable-next-line -- First render only
  }, [])

  if (state.loaded instanceof Error) throw state.loaded
  if (!state.loaded)
    return (
      <div className='full centered'>
        <Loading />
      </div>
    )

  // // If organizations have not been fetched, prefetch them
  // if (!queryClient.getQueryCache().find(organizationsQueryKey())) {
  //   queryClient
  //     .prefetchQuery(organizationsQueryKey(), organizationsQueryFn())
  //     .then(() => console.debug('Preloaded organizations')) // Dangle
  // }

  return (
    <KhhContext.Provider
      value={{
        ...state,
        id: REALM_APP_ID,
        registerUser: async (email: string, password: string) => {
          return await app.emailPasswordAuth.registerUser(email, password)
        },
        initiateResetPassword: async (email: string) => {
          if (!email || email === '') throw new Error('Must provide email')
          try {
            // Random password because we provide the real one in confirmResetPassword
            await app.emailPasswordAuth.callResetPasswordFunction(
              email,
              Math.random().toString(36).substring(2, 30)
            )
          } catch (e: any) {
            if (e.message.includes('user not found')) {
              e.displayMessage = 'There is no user with that email'
              e.message = `Could not reset password; no user with email: ${email}`
            }
            throw e
          }
        },
        confirmResetPassword: async (token, tokenId, newPassword) => {
          if (!newPassword || newPassword === '')
            throw new Error('Must provide new password')
          await app.emailPasswordAuth.resetPassword(token, tokenId, newPassword)
        },
        refresh: async () => setAccessToken(await refreshAccessToken(true)),
        logIn: async (email: string, pass: string, remember: boolean) => {
          const credentials = RealmWeb.Credentials.emailPassword(email, pass)
          const auth = await app.logIn(credentials, false)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const accessToken = auth._accessToken,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            refreshToken = auth._refreshToken
          setAccessToken(accessToken)
          setRefreshToken(refreshToken, remember)
        },
        logOut: async () => {
          clearSession()
          app.currentUser?.logOut()
          if (resetClient) resetClient()
          dispatch({ type: 'logout' })
        },
      }}
    >
      {children}
    </KhhContext.Provider>
  )
}

const useKhh = (): KhhContext => {
  const khhContext = React.useContext(KhhContext)
  if (!khhContext)
    throw new Error('You must call useKhh() inside of a KhhContext')
  return khhContext
}

export default useKhh
