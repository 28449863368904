import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `DateTime` scalar type represents a DateTime. The DateTime is serialized as an RFC 3339 quoted string */
  DateTime: any
  ObjectId: any
}

export type AdminSearchInput = {
  query?: Maybe<Scalars['String']>
}

export type AdminSearchResultItem = {
  __typename?: 'AdminSearchResultItem'
  _id: Scalars['ObjectId']
  description: Scalars['String']
  organization: Scalars['ObjectId']
  title: Scalars['String']
  trail?: Maybe<Scalars['ObjectId']>
  type: Scalars['String']
}

export type Authorization = {
  __typename?: 'Authorization'
  _id: Scalars['ObjectId']
  email: Scalars['String']
  organization: Scalars['ObjectId']
  startTime?: Maybe<Scalars['DateTime']>
  trail: Scalars['ObjectId']
}

export type Authorization2 = {
  __typename?: 'Authorization2'
  _id: Scalars['ObjectId']
  description?: Maybe<Scalars['String']>
  groups: Array<Maybe<Scalars['ObjectId']>>
  invitations: Array<Maybe<Scalars['String']>>
  name: Scalars['String']
  organization: Scalars['ObjectId']
  things: Array<Maybe<Scalars['ObjectId']>>
  users: Array<Maybe<Scalars['ObjectId']>>
}

export type Authorization2InsertInput = {
  description?: Maybe<Scalars['String']>
  groups: Array<Maybe<Scalars['ObjectId']>>
  invitations: Array<Maybe<Scalars['String']>>
  name: Scalars['String']
  organization: Scalars['ObjectId']
  things: Array<Maybe<Scalars['ObjectId']>>
  users: Array<Maybe<Scalars['ObjectId']>>
  _id?: Maybe<Scalars['ObjectId']>
}

export type Authorization2QueryInput = {
  name_exists?: Maybe<Scalars['Boolean']>
  _id_ne?: Maybe<Scalars['ObjectId']>
  users_exists?: Maybe<Scalars['Boolean']>
  organization_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  description_gte?: Maybe<Scalars['String']>
  groups?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  organization_exists?: Maybe<Scalars['Boolean']>
  _id_lt?: Maybe<Scalars['ObjectId']>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  groups_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_lt?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['ObjectId']>
  name_gte?: Maybe<Scalars['String']>
  _id_gt?: Maybe<Scalars['ObjectId']>
  things?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  organization_lt?: Maybe<Scalars['ObjectId']>
  description_gt?: Maybe<Scalars['String']>
  invitations?: Maybe<Array<Maybe<Scalars['String']>>>
  invitations_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_lte?: Maybe<Scalars['String']>
  description_ne?: Maybe<Scalars['String']>
  _id_exists?: Maybe<Scalars['Boolean']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  name?: Maybe<Scalars['String']>
  things_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  description?: Maybe<Scalars['String']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  name_ne?: Maybe<Scalars['String']>
  things_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  invitations_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_gt?: Maybe<Scalars['ObjectId']>
  organization_lte?: Maybe<Scalars['ObjectId']>
  users_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  users?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  name_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  description_exists?: Maybe<Scalars['Boolean']>
  things_exists?: Maybe<Scalars['Boolean']>
  AND?: Maybe<Array<Authorization2QueryInput>>
  name_gt?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Authorization2QueryInput>>
  groups_exists?: Maybe<Scalars['Boolean']>
  description_lt?: Maybe<Scalars['String']>
  invitations_exists?: Maybe<Scalars['Boolean']>
  groups_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  organization_gte?: Maybe<Scalars['ObjectId']>
  organization_ne?: Maybe<Scalars['ObjectId']>
  name_lte?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['ObjectId']>
  organization_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  users_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export enum Authorization2SortByInput {
  NameDesc = 'NAME_DESC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  NameAsc = 'NAME_ASC',
}

export type Authorization2UpdateInput = {
  things_unset?: Maybe<Scalars['Boolean']>
  users?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  users_unset?: Maybe<Scalars['Boolean']>
  groups_unset?: Maybe<Scalars['Boolean']>
  name_unset?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  things?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  description?: Maybe<Scalars['String']>
  groups?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  description_unset?: Maybe<Scalars['Boolean']>
  invitations_unset?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['ObjectId']>
  organization_unset?: Maybe<Scalars['Boolean']>
  invitations?: Maybe<Array<Maybe<Scalars['String']>>>
  _id_unset?: Maybe<Scalars['Boolean']>
}

export type AuthorizationInsertInput = {
  email: Scalars['String']
  organization: Scalars['ObjectId']
  startTime?: Maybe<Scalars['DateTime']>
  trail: Scalars['ObjectId']
  _id?: Maybe<Scalars['ObjectId']>
}

export type AuthorizationQueryInput = {
  startTime_ne?: Maybe<Scalars['DateTime']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  _id_gt?: Maybe<Scalars['ObjectId']>
  organization_exists?: Maybe<Scalars['Boolean']>
  startTime_lte?: Maybe<Scalars['DateTime']>
  email_gt?: Maybe<Scalars['String']>
  trail_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>
  trail_exists?: Maybe<Scalars['Boolean']>
  _id_ne?: Maybe<Scalars['ObjectId']>
  email_lte?: Maybe<Scalars['String']>
  email_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  AND?: Maybe<Array<AuthorizationQueryInput>>
  startTime_gte?: Maybe<Scalars['DateTime']>
  trail_ne?: Maybe<Scalars['ObjectId']>
  organization_lte?: Maybe<Scalars['ObjectId']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  trail_lte?: Maybe<Scalars['ObjectId']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  startTime?: Maybe<Scalars['DateTime']>
  trail_lt?: Maybe<Scalars['ObjectId']>
  organization_ne?: Maybe<Scalars['ObjectId']>
  email_exists?: Maybe<Scalars['Boolean']>
  _id_lt?: Maybe<Scalars['ObjectId']>
  email?: Maybe<Scalars['String']>
  trail_gt?: Maybe<Scalars['ObjectId']>
  startTime_exists?: Maybe<Scalars['Boolean']>
  trail_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  OR?: Maybe<Array<AuthorizationQueryInput>>
  startTime_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  trail?: Maybe<Scalars['ObjectId']>
  email_gte?: Maybe<Scalars['String']>
  _id_exists?: Maybe<Scalars['Boolean']>
  email_lt?: Maybe<Scalars['String']>
  email_ne?: Maybe<Scalars['String']>
  organization_gt?: Maybe<Scalars['ObjectId']>
  organization_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  startTime_lt?: Maybe<Scalars['DateTime']>
  startTime_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  organization?: Maybe<Scalars['ObjectId']>
  organization_gte?: Maybe<Scalars['ObjectId']>
  organization_lt?: Maybe<Scalars['ObjectId']>
  _id?: Maybe<Scalars['ObjectId']>
  startTime_gt?: Maybe<Scalars['DateTime']>
  trail_gte?: Maybe<Scalars['ObjectId']>
  organization_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export enum AuthorizationSortByInput {
  IdAsc = '_ID_ASC',
  EmailDesc = 'EMAIL_DESC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  StarttimeAsc = 'STARTTIME_ASC',
  StarttimeDesc = 'STARTTIME_DESC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  TrailAsc = 'TRAIL_ASC',
  TrailDesc = 'TRAIL_DESC',
}

export type AuthorizationUpdateInput = {
  startTime_unset?: Maybe<Scalars['Boolean']>
  email_unset?: Maybe<Scalars['Boolean']>
  organization?: Maybe<Scalars['ObjectId']>
  email?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['ObjectId']>
  _id_unset?: Maybe<Scalars['Boolean']>
  organization_unset?: Maybe<Scalars['Boolean']>
  startTime?: Maybe<Scalars['DateTime']>
  trail?: Maybe<Scalars['ObjectId']>
  trail_unset?: Maybe<Scalars['Boolean']>
}

export type DefaultPayload = {
  __typename?: 'DefaultPayload'
  status: Scalars['String']
}

export type DeleteManyPayload = {
  __typename?: 'DeleteManyPayload'
  deletedCount: Scalars['Int']
}

export type GetNuggetsInput = {
  organization?: Maybe<Scalars['ObjectId']>
  trail?: Maybe<Scalars['ObjectId']>
}

export type GetTrailsInput = {
  organization?: Maybe<Scalars['ObjectId']>
}

export type Group = {
  __typename?: 'Group'
  _id: Scalars['ObjectId']
  emails: Array<Maybe<Scalars['String']>>
  invitations: Array<Maybe<Invitation>>
  name: Scalars['String']
  organization: Organization
  users: Array<Maybe<User>>
}

export type GroupInsertInput = {
  emails: Array<Maybe<Scalars['String']>>
  invitations: GroupInvitationsRelationInput
  name: Scalars['String']
  organization: GroupOrganizationRelationInput
  users: GroupUsersRelationInput
  _id?: Maybe<Scalars['ObjectId']>
}

export type GroupInvitationsRelationInput = {
  create?: Maybe<Array<Maybe<InvitationInsertInput>>>
  link?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export type GroupOrganizationRelationInput = {
  link?: Maybe<Scalars['ObjectId']>
  create?: Maybe<OrganizationInsertInput>
}

export type GroupQueryInput = {
  name_gte?: Maybe<Scalars['String']>
  name_exists?: Maybe<Scalars['Boolean']>
  users_nin?: Maybe<Array<Maybe<UserQueryInput>>>
  emails?: Maybe<Array<Maybe<Scalars['String']>>>
  users_exists?: Maybe<Scalars['Boolean']>
  invitations?: Maybe<Array<Maybe<InvitationQueryInput>>>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  emails_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  name_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_exists?: Maybe<Scalars['Boolean']>
  emails_in?: Maybe<Array<Maybe<Scalars['String']>>>
  _id_gt?: Maybe<Scalars['ObjectId']>
  name?: Maybe<Scalars['String']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  invitations_in?: Maybe<Array<Maybe<InvitationQueryInput>>>
  users?: Maybe<Array<Maybe<UserQueryInput>>>
  invitations_exists?: Maybe<Scalars['Boolean']>
  users_in?: Maybe<Array<Maybe<UserQueryInput>>>
  _id_exists?: Maybe<Scalars['Boolean']>
  name_ne?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  name_gt?: Maybe<Scalars['String']>
  organization?: Maybe<OrganizationQueryInput>
  _id_lt?: Maybe<Scalars['ObjectId']>
  name_lt?: Maybe<Scalars['String']>
  OR?: Maybe<Array<GroupQueryInput>>
  AND?: Maybe<Array<GroupQueryInput>>
  _id_ne?: Maybe<Scalars['ObjectId']>
  emails_exists?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  invitations_nin?: Maybe<Array<Maybe<InvitationQueryInput>>>
}

export enum GroupSortByInput {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export type GroupUpdateInput = {
  users?: Maybe<GroupUsersRelationInput>
  invitations?: Maybe<GroupInvitationsRelationInput>
  invitations_unset?: Maybe<Scalars['Boolean']>
  name_unset?: Maybe<Scalars['Boolean']>
  emails?: Maybe<Array<Maybe<Scalars['String']>>>
  emails_unset?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  organization_unset?: Maybe<Scalars['Boolean']>
  users_unset?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  _id_unset?: Maybe<Scalars['Boolean']>
  organization?: Maybe<GroupOrganizationRelationInput>
}

export type GroupUsersRelationInput = {
  create?: Maybe<Array<Maybe<UserInsertInput>>>
  link?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export type InsertManyPayload = {
  __typename?: 'InsertManyPayload'
  insertedIds: Array<Maybe<Scalars['ObjectId']>>
}

export type Invitation = {
  __typename?: 'Invitation'
  _id: Scalars['ObjectId']
  accepted?: Maybe<Scalars['DateTime']>
  admin: Scalars['Boolean']
  completed?: Maybe<Scalars['DateTime']>
  counter?: Maybe<Scalars['Float']>
  defaults?: Maybe<InvitationDefault>
  email: Scalars['String']
  issued: Scalars['DateTime']
  organization: Scalars['ObjectId']
  staged?: Maybe<Scalars['Boolean']>
  token: Scalars['String']
  viewed?: Maybe<Scalars['DateTime']>
}

export type InvitationDefault = {
  __typename?: 'InvitationDefault'
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
}

export type InvitationDefaultInsertInput = {
  timezone?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export type InvitationDefaultQueryInput = {
  timezone_ne?: Maybe<Scalars['String']>
  firstName_lte?: Maybe<Scalars['String']>
  lastName_ne?: Maybe<Scalars['String']>
  lastName_gt?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  OR?: Maybe<Array<InvitationDefaultQueryInput>>
  timezone_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  timezone_exists?: Maybe<Scalars['Boolean']>
  timezone_lte?: Maybe<Scalars['String']>
  firstName_exists?: Maybe<Scalars['Boolean']>
  firstName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  lastName_lt?: Maybe<Scalars['String']>
  timezone_gt?: Maybe<Scalars['String']>
  firstName_gte?: Maybe<Scalars['String']>
  lastName_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  timezone?: Maybe<Scalars['String']>
  timezone_gte?: Maybe<Scalars['String']>
  firstName_ne?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  lastName_exists?: Maybe<Scalars['Boolean']>
  lastName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  lastName_lte?: Maybe<Scalars['String']>
  AND?: Maybe<Array<InvitationDefaultQueryInput>>
  firstName_gt?: Maybe<Scalars['String']>
  firstName_lt?: Maybe<Scalars['String']>
  firstName_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  lastName_gte?: Maybe<Scalars['String']>
  timezone_lt?: Maybe<Scalars['String']>
  timezone_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type InvitationDefaultUpdateInput = {
  timezone_unset?: Maybe<Scalars['Boolean']>
  firstName?: Maybe<Scalars['String']>
  firstName_unset?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  lastName_unset?: Maybe<Scalars['Boolean']>
  timezone?: Maybe<Scalars['String']>
}

export type InvitationForTokenInput = {
  token?: Maybe<Scalars['String']>
}

export type InvitationInsertInput = {
  _id?: Maybe<Scalars['ObjectId']>
  accepted?: Maybe<Scalars['DateTime']>
  token: Scalars['String']
  completed?: Maybe<Scalars['DateTime']>
  defaults?: Maybe<InvitationDefaultInsertInput>
  email: Scalars['String']
  viewed?: Maybe<Scalars['DateTime']>
  issued: Scalars['DateTime']
  admin: Scalars['Boolean']
  counter?: Maybe<Scalars['Float']>
  organization: Scalars['ObjectId']
  staged?: Maybe<Scalars['Boolean']>
}

export type InvitationQueryInput = {
  organization_gt?: Maybe<Scalars['ObjectId']>
  organization_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  viewed_lt?: Maybe<Scalars['DateTime']>
  viewed_gte?: Maybe<Scalars['DateTime']>
  email_exists?: Maybe<Scalars['Boolean']>
  completed_exists?: Maybe<Scalars['Boolean']>
  completed_gte?: Maybe<Scalars['DateTime']>
  completed_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  viewed_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  viewed_gt?: Maybe<Scalars['DateTime']>
  issued_gt?: Maybe<Scalars['DateTime']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  issued_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  email_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  viewed_ne?: Maybe<Scalars['DateTime']>
  email_gte?: Maybe<Scalars['String']>
  admin?: Maybe<Scalars['Boolean']>
  _id_exists?: Maybe<Scalars['Boolean']>
  defaults_exists?: Maybe<Scalars['Boolean']>
  viewed_lte?: Maybe<Scalars['DateTime']>
  counter_ne?: Maybe<Scalars['Float']>
  token_gt?: Maybe<Scalars['String']>
  completed_lte?: Maybe<Scalars['DateTime']>
  issued_exists?: Maybe<Scalars['Boolean']>
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>
  completed?: Maybe<Scalars['DateTime']>
  completed_ne?: Maybe<Scalars['DateTime']>
  viewed_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  organization_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  organization_lte?: Maybe<Scalars['ObjectId']>
  viewed?: Maybe<Scalars['DateTime']>
  organization?: Maybe<Scalars['ObjectId']>
  email_lt?: Maybe<Scalars['String']>
  organization_ne?: Maybe<Scalars['ObjectId']>
  admin_exists?: Maybe<Scalars['Boolean']>
  counter?: Maybe<Scalars['Float']>
  organization_exists?: Maybe<Scalars['Boolean']>
  token_lte?: Maybe<Scalars['String']>
  admin_ne?: Maybe<Scalars['Boolean']>
  accepted_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  issued_gte?: Maybe<Scalars['DateTime']>
  organization_lt?: Maybe<Scalars['ObjectId']>
  issued_lt?: Maybe<Scalars['DateTime']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  counter_gt?: Maybe<Scalars['Float']>
  AND?: Maybe<Array<InvitationQueryInput>>
  _id_lt?: Maybe<Scalars['ObjectId']>
  email?: Maybe<Scalars['String']>
  accepted_lt?: Maybe<Scalars['DateTime']>
  staged_ne?: Maybe<Scalars['Boolean']>
  completed_gt?: Maybe<Scalars['DateTime']>
  email_gt?: Maybe<Scalars['String']>
  counter_lte?: Maybe<Scalars['Float']>
  viewed_exists?: Maybe<Scalars['Boolean']>
  token_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  accepted_gte?: Maybe<Scalars['DateTime']>
  email_lte?: Maybe<Scalars['String']>
  issued_lte?: Maybe<Scalars['DateTime']>
  completed_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  token?: Maybe<Scalars['String']>
  email_ne?: Maybe<Scalars['String']>
  counter_lt?: Maybe<Scalars['Float']>
  token_ne?: Maybe<Scalars['String']>
  completed_lt?: Maybe<Scalars['DateTime']>
  token_gte?: Maybe<Scalars['String']>
  accepted_lte?: Maybe<Scalars['DateTime']>
  accepted_gt?: Maybe<Scalars['DateTime']>
  OR?: Maybe<Array<InvitationQueryInput>>
  counter_exists?: Maybe<Scalars['Boolean']>
  accepted?: Maybe<Scalars['DateTime']>
  staged?: Maybe<Scalars['Boolean']>
  _id_ne?: Maybe<Scalars['ObjectId']>
  accepted_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  accepted_ne?: Maybe<Scalars['DateTime']>
  issued_ne?: Maybe<Scalars['DateTime']>
  issued?: Maybe<Scalars['DateTime']>
  counter_gte?: Maybe<Scalars['Float']>
  defaults?: Maybe<InvitationDefaultQueryInput>
  _id?: Maybe<Scalars['ObjectId']>
  staged_exists?: Maybe<Scalars['Boolean']>
  token_in?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_gte?: Maybe<Scalars['ObjectId']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  _id_gt?: Maybe<Scalars['ObjectId']>
  token_exists?: Maybe<Scalars['Boolean']>
  accepted_exists?: Maybe<Scalars['Boolean']>
  token_lt?: Maybe<Scalars['String']>
  counter_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  counter_nin?: Maybe<Array<Maybe<Scalars['Float']>>>
  issued_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>
}

export type InvitationSearchInput = {
  filter?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export enum InvitationSortByInput {
  CounterDesc = 'COUNTER_DESC',
  IssuedDesc = 'ISSUED_DESC',
  IdDesc = '_ID_DESC',
  AcceptedAsc = 'ACCEPTED_ASC',
  CompletedAsc = 'COMPLETED_ASC',
  IssuedAsc = 'ISSUED_ASC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  TokenAsc = 'TOKEN_ASC',
  ViewedAsc = 'VIEWED_ASC',
  AcceptedDesc = 'ACCEPTED_DESC',
  CompletedDesc = 'COMPLETED_DESC',
  CounterAsc = 'COUNTER_ASC',
  EmailAsc = 'EMAIL_ASC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  TokenDesc = 'TOKEN_DESC',
  ViewedDesc = 'VIEWED_DESC',
  IdAsc = '_ID_ASC',
  EmailDesc = 'EMAIL_DESC',
}

export type InvitationUpdateInput = {
  accepted_unset?: Maybe<Scalars['Boolean']>
  completed_unset?: Maybe<Scalars['Boolean']>
  staged_unset?: Maybe<Scalars['Boolean']>
  admin?: Maybe<Scalars['Boolean']>
  completed?: Maybe<Scalars['DateTime']>
  accepted?: Maybe<Scalars['DateTime']>
  issued?: Maybe<Scalars['DateTime']>
  organization?: Maybe<Scalars['ObjectId']>
  admin_unset?: Maybe<Scalars['Boolean']>
  viewed_unset?: Maybe<Scalars['Boolean']>
  viewed?: Maybe<Scalars['DateTime']>
  counter_inc?: Maybe<Scalars['Float']>
  issued_unset?: Maybe<Scalars['Boolean']>
  _id_unset?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
  token_unset?: Maybe<Scalars['Boolean']>
  email_unset?: Maybe<Scalars['Boolean']>
  counter_unset?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  counter?: Maybe<Scalars['Float']>
  defaults?: Maybe<InvitationDefaultUpdateInput>
  defaults_unset?: Maybe<Scalars['Boolean']>
  organization_unset?: Maybe<Scalars['Boolean']>
  staged?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addDetails?: Maybe<DefaultPayload>
  createAuthorization?: Maybe<Authorization>
  deleteManyAuthorization2s?: Maybe<DeleteManyPayload>
  deleteManyAuthorizations?: Maybe<DeleteManyPayload>
  deleteManyGroups?: Maybe<DeleteManyPayload>
  deleteManyInvitations?: Maybe<DeleteManyPayload>
  deleteManyNuggets?: Maybe<DeleteManyPayload>
  deleteManyOrganizations?: Maybe<DeleteManyPayload>
  deleteManyThings?: Maybe<DeleteManyPayload>
  deleteManyTrails?: Maybe<DeleteManyPayload>
  deleteManyUsers?: Maybe<DeleteManyPayload>
  deleteOneAuthorization?: Maybe<Authorization>
  deleteOneAuthorization2?: Maybe<Authorization2>
  deleteOneGroup?: Maybe<Group>
  deleteOneInvitation?: Maybe<Invitation>
  deleteOneNugget?: Maybe<Nugget>
  deleteOneOrganization?: Maybe<Organization>
  deleteOneThing?: Maybe<Thing>
  deleteOneTrail?: Maybe<Trail>
  deleteOneUser?: Maybe<User>
  deleteUser?: Maybe<DefaultPayload>
  insertManyAuthorization2s?: Maybe<InsertManyPayload>
  insertManyAuthorizations?: Maybe<InsertManyPayload>
  insertManyGroups?: Maybe<InsertManyPayload>
  insertManyInvitations?: Maybe<InsertManyPayload>
  insertManyNuggets?: Maybe<InsertManyPayload>
  insertManyOrganizations?: Maybe<InsertManyPayload>
  insertManyThings?: Maybe<InsertManyPayload>
  insertManyTrails?: Maybe<InsertManyPayload>
  insertManyUsers?: Maybe<InsertManyPayload>
  insertOneAuthorization?: Maybe<Authorization>
  insertOneAuthorization2?: Maybe<Authorization2>
  insertOneGroup?: Maybe<Group>
  insertOneInvitation?: Maybe<Invitation>
  insertOneNugget?: Maybe<Nugget>
  insertOneOrganization?: Maybe<Organization>
  insertOneThing?: Maybe<Thing>
  insertOneTrail?: Maybe<Trail>
  insertOneUser?: Maybe<User>
  replaceOneAuthorization?: Maybe<Authorization>
  replaceOneAuthorization2?: Maybe<Authorization2>
  replaceOneGroup?: Maybe<Group>
  replaceOneInvitation?: Maybe<Invitation>
  replaceOneNugget?: Maybe<Nugget>
  replaceOneOrganization?: Maybe<Organization>
  replaceOneThing?: Maybe<Thing>
  replaceOneTrail?: Maybe<Trail>
  replaceOneUser?: Maybe<User>
  resendInvitationEmail?: Maybe<DefaultPayload>
  sendInvitation?: Maybe<Invitation>
  updateManyAuthorization2s?: Maybe<UpdateManyPayload>
  updateManyAuthorizations?: Maybe<UpdateManyPayload>
  updateManyGroups?: Maybe<UpdateManyPayload>
  updateManyInvitations?: Maybe<UpdateManyPayload>
  updateManyNuggets?: Maybe<UpdateManyPayload>
  updateManyOrganizations?: Maybe<UpdateManyPayload>
  updateManyThings?: Maybe<UpdateManyPayload>
  updateManyTrails?: Maybe<UpdateManyPayload>
  updateManyUsers?: Maybe<UpdateManyPayload>
  updateOneAuthorization?: Maybe<Authorization>
  updateOneAuthorization2?: Maybe<Authorization2>
  updateOneGroup?: Maybe<Group>
  updateOneInvitation?: Maybe<Invitation>
  updateOneNugget?: Maybe<Nugget>
  updateOneOrganization?: Maybe<Organization>
  updateOneThing?: Maybe<Thing>
  updateOneTrail?: Maybe<Trail>
  updateOneUser?: Maybe<User>
  upsertOneAuthorization?: Maybe<Authorization>
  upsertOneAuthorization2?: Maybe<Authorization2>
  upsertOneGroup?: Maybe<Group>
  upsertOneInvitation?: Maybe<Invitation>
  upsertOneNugget?: Maybe<Nugget>
  upsertOneOrganization?: Maybe<Organization>
  upsertOneThing?: Maybe<Thing>
  upsertOneTrail?: Maybe<Trail>
  upsertOneUser?: Maybe<User>
}

export type MutationAddDetailsArgs = {
  input?: Maybe<InvitationDefaultInsertInput>
}

export type MutationCreateAuthorizationArgs = {
  input?: Maybe<AuthorizationInsertInput>
}

export type MutationDeleteManyAuthorization2sArgs = {
  query?: Maybe<Authorization2QueryInput>
}

export type MutationDeleteManyAuthorizationsArgs = {
  query?: Maybe<AuthorizationQueryInput>
}

export type MutationDeleteManyGroupsArgs = {
  query?: Maybe<GroupQueryInput>
}

export type MutationDeleteManyInvitationsArgs = {
  query?: Maybe<InvitationQueryInput>
}

export type MutationDeleteManyNuggetsArgs = {
  query?: Maybe<NuggetQueryInput>
}

export type MutationDeleteManyOrganizationsArgs = {
  query?: Maybe<OrganizationQueryInput>
}

export type MutationDeleteManyThingsArgs = {
  query?: Maybe<ThingQueryInput>
}

export type MutationDeleteManyTrailsArgs = {
  query?: Maybe<TrailQueryInput>
}

export type MutationDeleteManyUsersArgs = {
  query?: Maybe<UserQueryInput>
}

export type MutationDeleteOneAuthorizationArgs = {
  query: AuthorizationQueryInput
}

export type MutationDeleteOneAuthorization2Args = {
  query: Authorization2QueryInput
}

export type MutationDeleteOneGroupArgs = {
  query: GroupQueryInput
}

export type MutationDeleteOneInvitationArgs = {
  query: InvitationQueryInput
}

export type MutationDeleteOneNuggetArgs = {
  query: NuggetQueryInput
}

export type MutationDeleteOneOrganizationArgs = {
  query: OrganizationQueryInput
}

export type MutationDeleteOneThingArgs = {
  query: ThingQueryInput
}

export type MutationDeleteOneTrailArgs = {
  query: TrailQueryInput
}

export type MutationDeleteOneUserArgs = {
  query: UserQueryInput
}

export type MutationDeleteUserArgs = {
  input?: Maybe<UserQueryInput>
}

export type MutationInsertManyAuthorization2sArgs = {
  data: Array<Authorization2InsertInput>
}

export type MutationInsertManyAuthorizationsArgs = {
  data: Array<AuthorizationInsertInput>
}

export type MutationInsertManyGroupsArgs = {
  data: Array<GroupInsertInput>
}

export type MutationInsertManyInvitationsArgs = {
  data: Array<InvitationInsertInput>
}

export type MutationInsertManyNuggetsArgs = {
  data: Array<NuggetInsertInput>
}

export type MutationInsertManyOrganizationsArgs = {
  data: Array<OrganizationInsertInput>
}

export type MutationInsertManyThingsArgs = {
  data: Array<ThingInsertInput>
}

export type MutationInsertManyTrailsArgs = {
  data: Array<TrailInsertInput>
}

export type MutationInsertManyUsersArgs = {
  data: Array<UserInsertInput>
}

export type MutationInsertOneAuthorizationArgs = {
  data: AuthorizationInsertInput
}

export type MutationInsertOneAuthorization2Args = {
  data: Authorization2InsertInput
}

export type MutationInsertOneGroupArgs = {
  data: GroupInsertInput
}

export type MutationInsertOneInvitationArgs = {
  data: InvitationInsertInput
}

export type MutationInsertOneNuggetArgs = {
  data: NuggetInsertInput
}

export type MutationInsertOneOrganizationArgs = {
  data: OrganizationInsertInput
}

export type MutationInsertOneThingArgs = {
  data: ThingInsertInput
}

export type MutationInsertOneTrailArgs = {
  data: TrailInsertInput
}

export type MutationInsertOneUserArgs = {
  data: UserInsertInput
}

export type MutationReplaceOneAuthorizationArgs = {
  query?: Maybe<AuthorizationQueryInput>
  data: AuthorizationInsertInput
}

export type MutationReplaceOneAuthorization2Args = {
  query?: Maybe<Authorization2QueryInput>
  data: Authorization2InsertInput
}

export type MutationReplaceOneGroupArgs = {
  query?: Maybe<GroupQueryInput>
  data: GroupInsertInput
}

export type MutationReplaceOneInvitationArgs = {
  data: InvitationInsertInput
  query?: Maybe<InvitationQueryInput>
}

export type MutationReplaceOneNuggetArgs = {
  query?: Maybe<NuggetQueryInput>
  data: NuggetInsertInput
}

export type MutationReplaceOneOrganizationArgs = {
  query?: Maybe<OrganizationQueryInput>
  data: OrganizationInsertInput
}

export type MutationReplaceOneThingArgs = {
  query?: Maybe<ThingQueryInput>
  data: ThingInsertInput
}

export type MutationReplaceOneTrailArgs = {
  query?: Maybe<TrailQueryInput>
  data: TrailInsertInput
}

export type MutationReplaceOneUserArgs = {
  query?: Maybe<UserQueryInput>
  data: UserInsertInput
}

export type MutationResendInvitationEmailArgs = {
  input?: Maybe<ResendInvitationEmailInput>
}

export type MutationSendInvitationArgs = {
  input?: Maybe<SendInvitationInput>
}

export type MutationUpdateManyAuthorization2sArgs = {
  query?: Maybe<Authorization2QueryInput>
  set: Authorization2UpdateInput
}

export type MutationUpdateManyAuthorizationsArgs = {
  query?: Maybe<AuthorizationQueryInput>
  set: AuthorizationUpdateInput
}

export type MutationUpdateManyGroupsArgs = {
  query?: Maybe<GroupQueryInput>
  set: GroupUpdateInput
}

export type MutationUpdateManyInvitationsArgs = {
  query?: Maybe<InvitationQueryInput>
  set: InvitationUpdateInput
}

export type MutationUpdateManyNuggetsArgs = {
  query?: Maybe<NuggetQueryInput>
  set: NuggetUpdateInput
}

export type MutationUpdateManyOrganizationsArgs = {
  query?: Maybe<OrganizationQueryInput>
  set: OrganizationUpdateInput
}

export type MutationUpdateManyThingsArgs = {
  query?: Maybe<ThingQueryInput>
  set: ThingUpdateInput
}

export type MutationUpdateManyTrailsArgs = {
  query?: Maybe<TrailQueryInput>
  set: TrailUpdateInput
}

export type MutationUpdateManyUsersArgs = {
  query?: Maybe<UserQueryInput>
  set: UserUpdateInput
}

export type MutationUpdateOneAuthorizationArgs = {
  query?: Maybe<AuthorizationQueryInput>
  set: AuthorizationUpdateInput
}

export type MutationUpdateOneAuthorization2Args = {
  set: Authorization2UpdateInput
  query?: Maybe<Authorization2QueryInput>
}

export type MutationUpdateOneGroupArgs = {
  query?: Maybe<GroupQueryInput>
  set: GroupUpdateInput
}

export type MutationUpdateOneInvitationArgs = {
  query?: Maybe<InvitationQueryInput>
  set: InvitationUpdateInput
}

export type MutationUpdateOneNuggetArgs = {
  query?: Maybe<NuggetQueryInput>
  set: NuggetUpdateInput
}

export type MutationUpdateOneOrganizationArgs = {
  query?: Maybe<OrganizationQueryInput>
  set: OrganizationUpdateInput
}

export type MutationUpdateOneThingArgs = {
  query?: Maybe<ThingQueryInput>
  set: ThingUpdateInput
}

export type MutationUpdateOneTrailArgs = {
  query?: Maybe<TrailQueryInput>
  set: TrailUpdateInput
}

export type MutationUpdateOneUserArgs = {
  query?: Maybe<UserQueryInput>
  set: UserUpdateInput
}

export type MutationUpsertOneAuthorizationArgs = {
  query?: Maybe<AuthorizationQueryInput>
  data: AuthorizationInsertInput
}

export type MutationUpsertOneAuthorization2Args = {
  query?: Maybe<Authorization2QueryInput>
  data: Authorization2InsertInput
}

export type MutationUpsertOneGroupArgs = {
  query?: Maybe<GroupQueryInput>
  data: GroupInsertInput
}

export type MutationUpsertOneInvitationArgs = {
  query?: Maybe<InvitationQueryInput>
  data: InvitationInsertInput
}

export type MutationUpsertOneNuggetArgs = {
  data: NuggetInsertInput
  query?: Maybe<NuggetQueryInput>
}

export type MutationUpsertOneOrganizationArgs = {
  data: OrganizationInsertInput
  query?: Maybe<OrganizationQueryInput>
}

export type MutationUpsertOneThingArgs = {
  query?: Maybe<ThingQueryInput>
  data: ThingInsertInput
}

export type MutationUpsertOneTrailArgs = {
  query?: Maybe<TrailQueryInput>
  data: TrailInsertInput
}

export type MutationUpsertOneUserArgs = {
  query?: Maybe<UserQueryInput>
  data: UserInsertInput
}

export type Nugget = {
  __typename?: 'Nugget'
  _id: Scalars['ObjectId']
  delay?: Maybe<Scalars['Float']>
  description: Scalars['String']
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Float']>
  organization: Scalars['ObjectId']
  title: Scalars['String']
  trail: Scalars['ObjectId']
  url?: Maybe<Scalars['String']>
}

export type NuggetInsertInput = {
  _id?: Maybe<Scalars['ObjectId']>
  delay?: Maybe<Scalars['Float']>
  description: Scalars['String']
  title: Scalars['String']
  url?: Maybe<Scalars['String']>
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Float']>
  organization: Scalars['ObjectId']
  trail: Scalars['ObjectId']
}

export type NuggetQueryInput = {
  descriptionIsHtml_ne?: Maybe<Scalars['Boolean']>
  order_ne?: Maybe<Scalars['Float']>
  AND?: Maybe<Array<NuggetQueryInput>>
  description?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  delay_exists?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  image_in?: Maybe<Array<Maybe<Scalars['String']>>>
  image_lte?: Maybe<Scalars['String']>
  organization_gte?: Maybe<Scalars['ObjectId']>
  order_nin?: Maybe<Array<Maybe<Scalars['Float']>>>
  trail_ne?: Maybe<Scalars['ObjectId']>
  url_lte?: Maybe<Scalars['String']>
  description_ne?: Maybe<Scalars['String']>
  description_lte?: Maybe<Scalars['String']>
  title_exists?: Maybe<Scalars['Boolean']>
  delay_lte?: Maybe<Scalars['Float']>
  description_lt?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  image_lt?: Maybe<Scalars['String']>
  organization_exists?: Maybe<Scalars['Boolean']>
  organization_ne?: Maybe<Scalars['ObjectId']>
  delay_nin?: Maybe<Array<Maybe<Scalars['Float']>>>
  _id_exists?: Maybe<Scalars['Boolean']>
  _id_lt?: Maybe<Scalars['ObjectId']>
  delay_gt?: Maybe<Scalars['Float']>
  title_ne?: Maybe<Scalars['String']>
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  trail_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  order?: Maybe<Scalars['Float']>
  image_gt?: Maybe<Scalars['String']>
  url_ne?: Maybe<Scalars['String']>
  order_gt?: Maybe<Scalars['Float']>
  order_exists?: Maybe<Scalars['Boolean']>
  url_lt?: Maybe<Scalars['String']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  _id_gt?: Maybe<Scalars['ObjectId']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  image_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  delay_gte?: Maybe<Scalars['Float']>
  description_gt?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  organization_lt?: Maybe<Scalars['ObjectId']>
  url_exists?: Maybe<Scalars['Boolean']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  order_lte?: Maybe<Scalars['Float']>
  organization_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  trail_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  title_lte?: Maybe<Scalars['String']>
  url_gt?: Maybe<Scalars['String']>
  url_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  description_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  order_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  OR?: Maybe<Array<NuggetQueryInput>>
  _id_ne?: Maybe<Scalars['ObjectId']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  order_lt?: Maybe<Scalars['Float']>
  delay_ne?: Maybe<Scalars['Float']>
  image_ne?: Maybe<Scalars['String']>
  organization_gt?: Maybe<Scalars['ObjectId']>
  organization_lte?: Maybe<Scalars['ObjectId']>
  order_gte?: Maybe<Scalars['Float']>
  trail_gt?: Maybe<Scalars['ObjectId']>
  delay_lt?: Maybe<Scalars['Float']>
  description_exists?: Maybe<Scalars['Boolean']>
  delay_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  descriptionIsHtml_exists?: Maybe<Scalars['Boolean']>
  trail_lt?: Maybe<Scalars['ObjectId']>
  url?: Maybe<Scalars['String']>
  delay?: Maybe<Scalars['Float']>
  title_gte?: Maybe<Scalars['String']>
  trail?: Maybe<Scalars['ObjectId']>
  organization?: Maybe<Scalars['ObjectId']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  url_gte?: Maybe<Scalars['String']>
  trail_gte?: Maybe<Scalars['ObjectId']>
  title_gt?: Maybe<Scalars['String']>
  description_gte?: Maybe<Scalars['String']>
  organization_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  image_exists?: Maybe<Scalars['Boolean']>
  image_gte?: Maybe<Scalars['String']>
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  title_lt?: Maybe<Scalars['String']>
  trail_lte?: Maybe<Scalars['ObjectId']>
  trail_exists?: Maybe<Scalars['Boolean']>
}

export enum NuggetSortByInput {
  DelayAsc = 'DELAY_ASC',
  ImageAsc = 'IMAGE_ASC',
  OrderDesc = 'ORDER_DESC',
  TrailDesc = 'TRAIL_DESC',
  DelayDesc = 'DELAY_DESC',
  ImageDesc = 'IMAGE_DESC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  TrailAsc = 'TRAIL_ASC',
  IdAsc = '_ID_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  OrderAsc = 'ORDER_ASC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  IdDesc = '_ID_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  OrganizationDesc = 'ORGANIZATION_DESC',
}

export type NuggetUpdateInput = {
  title_unset?: Maybe<Scalars['Boolean']>
  _id_unset?: Maybe<Scalars['Boolean']>
  delay?: Maybe<Scalars['Float']>
  title?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['ObjectId']>
  image?: Maybe<Scalars['String']>
  delay_unset?: Maybe<Scalars['Boolean']>
  delay_inc?: Maybe<Scalars['Float']>
  trail_unset?: Maybe<Scalars['Boolean']>
  url_unset?: Maybe<Scalars['Boolean']>
  organization_unset?: Maybe<Scalars['Boolean']>
  image_unset?: Maybe<Scalars['Boolean']>
  order_inc?: Maybe<Scalars['Float']>
  order_unset?: Maybe<Scalars['Boolean']>
  trail?: Maybe<Scalars['ObjectId']>
  order?: Maybe<Scalars['Float']>
  url?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['ObjectId']>
  description?: Maybe<Scalars['String']>
  description_unset?: Maybe<Scalars['Boolean']>
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  descriptionIsHtml_unset?: Maybe<Scalars['Boolean']>
}

export type Organization = {
  __typename?: 'Organization'
  _id: Scalars['ObjectId']
  hubName?: Maybe<Scalars['String']>
  largeLogo?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  name: Scalars['String']
  operator: Scalars['Boolean']
  primary?: Maybe<Scalars['String']>
  secondary?: Maybe<Scalars['String']>
  tertiary?: Maybe<Scalars['String']>
}

export type OrganizationInsertInput = {
  hubName?: Maybe<Scalars['String']>
  name: Scalars['String']
  _id?: Maybe<Scalars['ObjectId']>
  logo?: Maybe<Scalars['String']>
  operator: Scalars['Boolean']
  largeLogo?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['String']>
  secondary?: Maybe<Scalars['String']>
  tertiary?: Maybe<Scalars['String']>
}

export type OrganizationQueryInput = {
  primary_ne?: Maybe<Scalars['String']>
  name_ne?: Maybe<Scalars['String']>
  primary_exists?: Maybe<Scalars['Boolean']>
  primary_lte?: Maybe<Scalars['String']>
  largeLogo_in?: Maybe<Array<Maybe<Scalars['String']>>>
  _id_gt?: Maybe<Scalars['ObjectId']>
  hubName_gt?: Maybe<Scalars['String']>
  tertiary_lte?: Maybe<Scalars['String']>
  secondary_gte?: Maybe<Scalars['String']>
  tertiary_lt?: Maybe<Scalars['String']>
  primary_gte?: Maybe<Scalars['String']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  largeLogo?: Maybe<Scalars['String']>
  hubName_lt?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['ObjectId']>
  hubName_lte?: Maybe<Scalars['String']>
  name_lt?: Maybe<Scalars['String']>
  secondary_gt?: Maybe<Scalars['String']>
  primary_gt?: Maybe<Scalars['String']>
  largeLogo_lt?: Maybe<Scalars['String']>
  secondary_in?: Maybe<Array<Maybe<Scalars['String']>>>
  operator?: Maybe<Scalars['Boolean']>
  logo_lte?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  _id_ne?: Maybe<Scalars['ObjectId']>
  largeLogo_gt?: Maybe<Scalars['String']>
  hubName?: Maybe<Scalars['String']>
  name_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  largeLogo_gte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  _id_exists?: Maybe<Scalars['Boolean']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  secondary_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  logo_in?: Maybe<Array<Maybe<Scalars['String']>>>
  secondary_lt?: Maybe<Scalars['String']>
  tertiary_ne?: Maybe<Scalars['String']>
  tertiary_gt?: Maybe<Scalars['String']>
  logo_gt?: Maybe<Scalars['String']>
  logo_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  hubName_gte?: Maybe<Scalars['String']>
  largeLogo_lte?: Maybe<Scalars['String']>
  hubName_exists?: Maybe<Scalars['Boolean']>
  secondary_exists?: Maybe<Scalars['Boolean']>
  largeLogo_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  secondary_ne?: Maybe<Scalars['String']>
  hubName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  OR?: Maybe<Array<OrganizationQueryInput>>
  hubName_ne?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  tertiary_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  tertiary_gte?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['String']>
  hubName_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  largeLogo_ne?: Maybe<Scalars['String']>
  logo_gte?: Maybe<Scalars['String']>
  primary_in?: Maybe<Array<Maybe<Scalars['String']>>>
  AND?: Maybe<Array<OrganizationQueryInput>>
  primary_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  logo_ne?: Maybe<Scalars['String']>
  _id_lt?: Maybe<Scalars['ObjectId']>
  operator_ne?: Maybe<Scalars['Boolean']>
  largeLogo_exists?: Maybe<Scalars['Boolean']>
  tertiary?: Maybe<Scalars['String']>
  tertiary_exists?: Maybe<Scalars['Boolean']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  secondary_lte?: Maybe<Scalars['String']>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  secondary?: Maybe<Scalars['String']>
  operator_exists?: Maybe<Scalars['Boolean']>
  tertiary_in?: Maybe<Array<Maybe<Scalars['String']>>>
  primary_lt?: Maybe<Scalars['String']>
  logo_lt?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  logo_exists?: Maybe<Scalars['Boolean']>
  name_exists?: Maybe<Scalars['Boolean']>
}

export enum OrganizationSortByInput {
  SecondaryDesc = 'SECONDARY_DESC',
  TertiaryAsc = 'TERTIARY_ASC',
  TertiaryDesc = 'TERTIARY_DESC',
  HubnameDesc = 'HUBNAME_DESC',
  NameAsc = 'NAME_ASC',
  PrimaryAsc = 'PRIMARY_ASC',
  SecondaryAsc = 'SECONDARY_ASC',
  IdAsc = '_ID_ASC',
  LargelogoAsc = 'LARGELOGO_ASC',
  LogoAsc = 'LOGO_ASC',
  PrimaryDesc = 'PRIMARY_DESC',
  HubnameAsc = 'HUBNAME_ASC',
  LargelogoDesc = 'LARGELOGO_DESC',
  LogoDesc = 'LOGO_DESC',
  IdDesc = '_ID_DESC',
  NameDesc = 'NAME_DESC',
}

export type OrganizationUpdateInput = {
  tertiary?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['ObjectId']>
  _id_unset?: Maybe<Scalars['Boolean']>
  hubName?: Maybe<Scalars['String']>
  tertiary_unset?: Maybe<Scalars['Boolean']>
  hubName_unset?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_unset?: Maybe<Scalars['Boolean']>
  logo_unset?: Maybe<Scalars['Boolean']>
  logo?: Maybe<Scalars['String']>
  secondary?: Maybe<Scalars['String']>
  operator_unset?: Maybe<Scalars['Boolean']>
  secondary_unset?: Maybe<Scalars['Boolean']>
  operator?: Maybe<Scalars['Boolean']>
  primary?: Maybe<Scalars['String']>
  largeLogo_unset?: Maybe<Scalars['Boolean']>
  largeLogo?: Maybe<Scalars['String']>
  primary_unset?: Maybe<Scalars['Boolean']>
}

export type Query = {
  __typename?: 'Query'
  adminSearch?: Maybe<Array<Maybe<AdminSearchResultItem>>>
  authorization?: Maybe<Authorization>
  authorization2?: Maybe<Authorization2>
  authorization2s: Array<Maybe<Authorization2>>
  authorizations: Array<Maybe<Authorization>>
  getNuggets?: Maybe<Array<Maybe<Nugget>>>
  getTrails?: Maybe<Array<Maybe<Trail>>>
  group?: Maybe<Group>
  groups: Array<Maybe<Group>>
  invitation?: Maybe<Invitation>
  invitationForToken?: Maybe<Invitation>
  invitationSearch?: Maybe<Array<Maybe<Invitation>>>
  invitations: Array<Maybe<Invitation>>
  myInvitation?: Maybe<Invitation>
  nugget?: Maybe<Nugget>
  nuggets: Array<Maybe<Nugget>>
  organization?: Maybe<Organization>
  organizations: Array<Maybe<Organization>>
  thing?: Maybe<Thing>
  things: Array<Maybe<Thing>>
  trail?: Maybe<Trail>
  trails: Array<Maybe<Trail>>
  user?: Maybe<User>
  userSearch?: Maybe<Array<Maybe<UserSearchResultItem>>>
  users: Array<Maybe<User>>
}

export type QueryAdminSearchArgs = {
  input?: Maybe<AdminSearchInput>
}

export type QueryAuthorizationArgs = {
  query?: Maybe<AuthorizationQueryInput>
}

export type QueryAuthorization2Args = {
  query?: Maybe<Authorization2QueryInput>
}

export type QueryAuthorization2sArgs = {
  query?: Maybe<Authorization2QueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<Authorization2SortByInput>
}

export type QueryAuthorizationsArgs = {
  query?: Maybe<AuthorizationQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<AuthorizationSortByInput>
}

export type QueryGetNuggetsArgs = {
  input?: Maybe<GetNuggetsInput>
}

export type QueryGetTrailsArgs = {
  input?: Maybe<GetTrailsInput>
}

export type QueryGroupArgs = {
  query?: Maybe<GroupQueryInput>
}

export type QueryGroupsArgs = {
  query?: Maybe<GroupQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<GroupSortByInput>
}

export type QueryInvitationArgs = {
  query?: Maybe<InvitationQueryInput>
}

export type QueryInvitationForTokenArgs = {
  input?: Maybe<InvitationForTokenInput>
}

export type QueryInvitationSearchArgs = {
  input?: Maybe<InvitationSearchInput>
}

export type QueryInvitationsArgs = {
  query?: Maybe<InvitationQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<InvitationSortByInput>
}

export type QueryNuggetArgs = {
  query?: Maybe<NuggetQueryInput>
}

export type QueryNuggetsArgs = {
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<NuggetSortByInput>
  query?: Maybe<NuggetQueryInput>
}

export type QueryOrganizationArgs = {
  query?: Maybe<OrganizationQueryInput>
}

export type QueryOrganizationsArgs = {
  query?: Maybe<OrganizationQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<OrganizationSortByInput>
}

export type QueryThingArgs = {
  query?: Maybe<ThingQueryInput>
}

export type QueryThingsArgs = {
  query?: Maybe<ThingQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<ThingSortByInput>
}

export type QueryTrailArgs = {
  query?: Maybe<TrailQueryInput>
}

export type QueryTrailsArgs = {
  query?: Maybe<TrailQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<TrailSortByInput>
}

export type QueryUserArgs = {
  query?: Maybe<UserQueryInput>
}

export type QueryUserSearchArgs = {
  input?: Maybe<UserSearchInput>
}

export type QueryUsersArgs = {
  query?: Maybe<UserQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<UserSortByInput>
}

export type ResendInvitationEmailInput = {
  _id: Scalars['ObjectId']
}

export type SendInvitationInput = {
  admin: Scalars['Boolean']
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['ObjectId']>
  timezone?: Maybe<Scalars['String']>
}

export type Thing = {
  __typename?: 'Thing'
  _id: Scalars['ObjectId']
  description: Scalars['String']
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  order?: Maybe<Scalars['Float']>
  organization: Scalars['ObjectId']
  parents: Array<Maybe<Scalars['ObjectId']>>
  private?: Maybe<Scalars['Boolean']>
  title: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type ThingInsertInput = {
  _id?: Maybe<Scalars['ObjectId']>
  organization: Scalars['ObjectId']
  title: Scalars['String']
  order?: Maybe<Scalars['Float']>
  private?: Maybe<Scalars['Boolean']>
  parents: Array<Maybe<Scalars['ObjectId']>>
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
  description: Scalars['String']
}

export type ThingQueryInput = {
  _id_ne?: Maybe<Scalars['ObjectId']>
  _id_exists?: Maybe<Scalars['Boolean']>
  organization_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  parents_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_lte?: Maybe<Scalars['ObjectId']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_ne?: Maybe<Scalars['String']>
  private_ne?: Maybe<Scalars['Boolean']>
  description_exists?: Maybe<Scalars['Boolean']>
  url_exists?: Maybe<Scalars['Boolean']>
  descriptionIsHtml_exists?: Maybe<Scalars['Boolean']>
  title_gt?: Maybe<Scalars['String']>
  OR?: Maybe<Array<ThingQueryInput>>
  description_lt?: Maybe<Scalars['String']>
  title_exists?: Maybe<Scalars['Boolean']>
  title_lt?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['ObjectId']>
  description_lte?: Maybe<Scalars['String']>
  description_ne?: Maybe<Scalars['String']>
  order_lt?: Maybe<Scalars['Float']>
  private_exists?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
  parents_exists?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  description_gte?: Maybe<Scalars['String']>
  parents?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description?: Maybe<Scalars['String']>
  description_gt?: Maybe<Scalars['String']>
  organization_lt?: Maybe<Scalars['ObjectId']>
  url_gt?: Maybe<Scalars['String']>
  order_gte?: Maybe<Scalars['Float']>
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  description_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  order_lte?: Maybe<Scalars['Float']>
  title_lte?: Maybe<Scalars['String']>
  parents_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  url_ne?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  url_lte?: Maybe<Scalars['String']>
  organization_gt?: Maybe<Scalars['ObjectId']>
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  order_gt?: Maybe<Scalars['Float']>
  AND?: Maybe<Array<ThingQueryInput>>
  title_gte?: Maybe<Scalars['String']>
  organization_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Float']>
  organization_gte?: Maybe<Scalars['ObjectId']>
  _id_lt?: Maybe<Scalars['ObjectId']>
  url_lt?: Maybe<Scalars['String']>
  organization_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  order_ne?: Maybe<Scalars['Float']>
  _id_gt?: Maybe<Scalars['ObjectId']>
  organization_ne?: Maybe<Scalars['ObjectId']>
  url_gte?: Maybe<Scalars['String']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  order_nin?: Maybe<Array<Maybe<Scalars['Float']>>>
  url_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  order_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  descriptionIsHtml_ne?: Maybe<Scalars['Boolean']>
  order_exists?: Maybe<Scalars['Boolean']>
}

export enum ThingSortByInput {
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  OrderDesc = 'ORDER_DESC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OrderAsc = 'ORDER_ASC',
}

export type ThingUpdateInput = {
  _id_unset?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['ObjectId']>
  title_unset?: Maybe<Scalars['Boolean']>
  description_unset?: Maybe<Scalars['Boolean']>
  order?: Maybe<Scalars['Float']>
  url?: Maybe<Scalars['String']>
  parents?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  organization_unset?: Maybe<Scalars['Boolean']>
  descriptionIsHtml_unset?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  private_unset?: Maybe<Scalars['Boolean']>
  descriptionIsHtml?: Maybe<Scalars['Boolean']>
  private?: Maybe<Scalars['Boolean']>
  parents_unset?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  order_unset?: Maybe<Scalars['Boolean']>
  url_unset?: Maybe<Scalars['Boolean']>
  order_inc?: Maybe<Scalars['Float']>
}

export type Trail = {
  __typename?: 'Trail'
  _id: Scalars['ObjectId']
  description: Scalars['String']
  image?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Float']>
  organization: Scalars['ObjectId']
  private: Scalars['Boolean']
  title: Scalars['String']
}

export type TrailInsertInput = {
  description: Scalars['String']
  image?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Float']>
  organization: Scalars['ObjectId']
  private: Scalars['Boolean']
  title: Scalars['String']
  _id?: Maybe<Scalars['ObjectId']>
}

export type TrailQueryInput = {
  title?: Maybe<Scalars['String']>
  image_exists?: Maybe<Scalars['Boolean']>
  description_ne?: Maybe<Scalars['String']>
  organization_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  organization_gte?: Maybe<Scalars['ObjectId']>
  order_ne?: Maybe<Scalars['Float']>
  private?: Maybe<Scalars['Boolean']>
  order_lte?: Maybe<Scalars['Float']>
  _id_ne?: Maybe<Scalars['ObjectId']>
  order_nin?: Maybe<Array<Maybe<Scalars['Float']>>>
  title_gte?: Maybe<Scalars['String']>
  description_gte?: Maybe<Scalars['String']>
  organization_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  _id_lte?: Maybe<Scalars['ObjectId']>
  organization_exists?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  organization?: Maybe<Scalars['ObjectId']>
  image_gt?: Maybe<Scalars['String']>
  order_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  _id_lt?: Maybe<Scalars['ObjectId']>
  AND?: Maybe<Array<TrailQueryInput>>
  order_gt?: Maybe<Scalars['Float']>
  order_gte?: Maybe<Scalars['Float']>
  title_ne?: Maybe<Scalars['String']>
  image_lte?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_exists?: Maybe<Scalars['Boolean']>
  image_gte?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['ObjectId']>
  _id_exists?: Maybe<Scalars['Boolean']>
  title_gt?: Maybe<Scalars['String']>
  private_ne?: Maybe<Scalars['Boolean']>
  image_ne?: Maybe<Scalars['String']>
  organization_lte?: Maybe<Scalars['ObjectId']>
  description_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  _id_gt?: Maybe<Scalars['ObjectId']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_gt?: Maybe<Scalars['String']>
  organization_lt?: Maybe<Scalars['ObjectId']>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  order_exists?: Maybe<Scalars['Boolean']>
  description_lte?: Maybe<Scalars['String']>
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  image_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_lte?: Maybe<Scalars['String']>
  order_lt?: Maybe<Scalars['Float']>
  description_lt?: Maybe<Scalars['String']>
  OR?: Maybe<Array<TrailQueryInput>>
  image_lt?: Maybe<Scalars['String']>
  private_exists?: Maybe<Scalars['Boolean']>
  title_lt?: Maybe<Scalars['String']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  image_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_gt?: Maybe<Scalars['ObjectId']>
  description_exists?: Maybe<Scalars['Boolean']>
  organization_ne?: Maybe<Scalars['ObjectId']>
}

export enum TrailSortByInput {
  IdDesc = '_ID_DESC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  ImageAsc = 'IMAGE_ASC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  TitleDesc = 'TITLE_DESC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  TitleAsc = 'TITLE_ASC',
  IdAsc = '_ID_ASC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  ImageDesc = 'IMAGE_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
}

export type TrailUpdateInput = {
  title_unset?: Maybe<Scalars['Boolean']>
  private?: Maybe<Scalars['Boolean']>
  organization?: Maybe<Scalars['ObjectId']>
  _id_unset?: Maybe<Scalars['Boolean']>
  order?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['ObjectId']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  image_unset?: Maybe<Scalars['Boolean']>
  order_inc?: Maybe<Scalars['Float']>
  order_unset?: Maybe<Scalars['Boolean']>
  organization_unset?: Maybe<Scalars['Boolean']>
  private_unset?: Maybe<Scalars['Boolean']>
  description_unset?: Maybe<Scalars['Boolean']>
}

export type UpdateManyPayload = {
  __typename?: 'UpdateManyPayload'
  matchedCount: Scalars['Int']
  modifiedCount: Scalars['Int']
}

export type User = {
  __typename?: 'User'
  _id: Scalars['ObjectId']
  admin: Scalars['Boolean']
  email: Scalars['String']
  firstName: Scalars['String']
  icon?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  myThings?: Maybe<Array<Maybe<Thing>>>
  organization?: Maybe<Scalars['ObjectId']>
  realmId: Scalars['String']
  userAgreement?: Maybe<Scalars['String']>
}

export type UserInsertInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  icon?: Maybe<Scalars['String']>
  realmId: Scalars['String']
  admin: Scalars['Boolean']
  organization?: Maybe<Scalars['ObjectId']>
  lastName: Scalars['String']
  userAgreement?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['ObjectId']>
}

export type UserQueryInput = {
  lastName_lte?: Maybe<Scalars['String']>
  userAgreement_in?: Maybe<Array<Maybe<Scalars['String']>>>
  userAgreement_lt?: Maybe<Scalars['String']>
  realmId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  email_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_lte?: Maybe<Scalars['ObjectId']>
  admin_ne?: Maybe<Scalars['Boolean']>
  realmId?: Maybe<Scalars['String']>
  realmId_lt?: Maybe<Scalars['String']>
  userAgreement_lte?: Maybe<Scalars['String']>
  realmId_lte?: Maybe<Scalars['String']>
  userAgreement?: Maybe<Scalars['String']>
  _id_ne?: Maybe<Scalars['ObjectId']>
  icon_lt?: Maybe<Scalars['String']>
  realmId_exists?: Maybe<Scalars['Boolean']>
  email_lt?: Maybe<Scalars['String']>
  realmId_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  userAgreement_ne?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  lastName_gt?: Maybe<Scalars['String']>
  userAgreement_gte?: Maybe<Scalars['String']>
  firstName_lte?: Maybe<Scalars['String']>
  icon_gte?: Maybe<Scalars['String']>
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  icon_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  userAgreement_gt?: Maybe<Scalars['String']>
  AND?: Maybe<Array<UserQueryInput>>
  lastName_ne?: Maybe<Scalars['String']>
  firstName_exists?: Maybe<Scalars['Boolean']>
  organization?: Maybe<Scalars['ObjectId']>
  organization_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  lastName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  firstName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  userAgreement_exists?: Maybe<Scalars['Boolean']>
  lastName_gte?: Maybe<Scalars['String']>
  email_lte?: Maybe<Scalars['String']>
  OR?: Maybe<Array<UserQueryInput>>
  organization_gt?: Maybe<Scalars['ObjectId']>
  organization_gte?: Maybe<Scalars['ObjectId']>
  firstName_lt?: Maybe<Scalars['String']>
  realmId_ne?: Maybe<Scalars['String']>
  _id_gt?: Maybe<Scalars['ObjectId']>
  icon_gt?: Maybe<Scalars['String']>
  firstName_gte?: Maybe<Scalars['String']>
  _id_lte?: Maybe<Scalars['ObjectId']>
  organization_lt?: Maybe<Scalars['ObjectId']>
  organization_exists?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  firstName_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  firstName_gt?: Maybe<Scalars['String']>
  icon_exists?: Maybe<Scalars['Boolean']>
  firstName_ne?: Maybe<Scalars['String']>
  admin_exists?: Maybe<Scalars['Boolean']>
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>
  realmId_gte?: Maybe<Scalars['String']>
  lastName_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_ne?: Maybe<Scalars['ObjectId']>
  lastName_lt?: Maybe<Scalars['String']>
  userAgreement_nin?: Maybe<Array<Maybe<Scalars['String']>>>
  lastName?: Maybe<Scalars['String']>
  _id_gte?: Maybe<Scalars['ObjectId']>
  email_exists?: Maybe<Scalars['Boolean']>
  email_gte?: Maybe<Scalars['String']>
  realmId_gt?: Maybe<Scalars['String']>
  email_gt?: Maybe<Scalars['String']>
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>
  organization_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  admin?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  email_ne?: Maybe<Scalars['String']>
  icon_lte?: Maybe<Scalars['String']>
  icon_ne?: Maybe<Scalars['String']>
  _id_lt?: Maybe<Scalars['ObjectId']>
  firstName?: Maybe<Scalars['String']>
  lastName_exists?: Maybe<Scalars['Boolean']>
  _id_exists?: Maybe<Scalars['Boolean']>
}

export type UserSearchInput = {
  query?: Maybe<Scalars['String']>
}

export type UserSearchResultItem = {
  __typename?: 'UserSearchResultItem'
  _id: Scalars['ObjectId']
  description: Scalars['String']
  organization: Scalars['ObjectId']
  title: Scalars['String']
  trail?: Maybe<Scalars['ObjectId']>
  type: Scalars['String']
}

export enum UserSortByInput {
  IconDesc = 'ICON_DESC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  UseragreementAsc = 'USERAGREEMENT_ASC',
  UseragreementDesc = 'USERAGREEMENT_DESC',
  IdDesc = '_ID_DESC',
  EmailDesc = 'EMAIL_DESC',
  FirstnameAsc = 'FIRSTNAME_ASC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  RealmidAsc = 'REALMID_ASC',
  IdAsc = '_ID_ASC',
  RealmidDesc = 'REALMID_DESC',
  LastnameAsc = 'LASTNAME_ASC',
  FirstnameDesc = 'FIRSTNAME_DESC',
  IconAsc = 'ICON_ASC',
  LastnameDesc = 'LASTNAME_DESC',
  EmailAsc = 'EMAIL_ASC',
}

export type UserUpdateInput = {
  icon_unset?: Maybe<Scalars['Boolean']>
  userAgreement_unset?: Maybe<Scalars['Boolean']>
  organization?: Maybe<Scalars['ObjectId']>
  _id_unset?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  admin_unset?: Maybe<Scalars['Boolean']>
  email_unset?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  userAgreement?: Maybe<Scalars['String']>
  admin?: Maybe<Scalars['Boolean']>
  firstName?: Maybe<Scalars['String']>
  firstName_unset?: Maybe<Scalars['Boolean']>
  _id?: Maybe<Scalars['ObjectId']>
  realmId?: Maybe<Scalars['String']>
  realmId_unset?: Maybe<Scalars['Boolean']>
  lastName_unset?: Maybe<Scalars['Boolean']>
  organization_unset?: Maybe<Scalars['Boolean']>
}

export type Authorization_AllFragment = { __typename?: 'Authorization' } & Pick<
  Authorization,
  '_id' | 'email' | 'organization' | 'trail' | 'startTime'
>

export type AuthorizationsQueryVariables = Exact<{
  query: AuthorizationQueryInput
}>

export type AuthorizationsQuery = { __typename?: 'Query' } & {
  authorizations: Array<
    Maybe<{ __typename?: 'Authorization' } & Authorization_AllFragment>
  >
}

export type AuthorizationQueryVariables = Exact<{
  query: AuthorizationQueryInput
}>

export type AuthorizationQuery = { __typename?: 'Query' } & {
  authorization?: Maybe<
    { __typename?: 'Authorization' } & Authorization_AllFragment
  >
}

export type InsertOneAuthorizationMutationVariables = Exact<{
  data: AuthorizationInsertInput
}>

export type InsertOneAuthorizationMutation = { __typename?: 'Mutation' } & {
  insertOneAuthorization?: Maybe<
    { __typename?: 'Authorization' } & Authorization_AllFragment
  >
}

export type InsertManyAuthorizationsMutationVariables = Exact<{
  data: Array<AuthorizationInsertInput> | AuthorizationInsertInput
}>

export type InsertManyAuthorizationsMutation = { __typename?: 'Mutation' } & {
  insertManyAuthorizations?: Maybe<
    { __typename?: 'InsertManyPayload' } & Pick<
      InsertManyPayload,
      'insertedIds'
    >
  >
}

export type UpdateOneAuthorizationMutationVariables = Exact<{
  query?: Maybe<AuthorizationQueryInput>
  set: AuthorizationUpdateInput
}>

export type UpdateOneAuthorizationMutation = { __typename?: 'Mutation' } & {
  updateOneAuthorization?: Maybe<
    { __typename?: 'Authorization' } & Authorization_AllFragment
  >
}

export type DeleteOneAuthorizationMutationVariables = Exact<{
  query: AuthorizationQueryInput
}>

export type DeleteOneAuthorizationMutation = { __typename?: 'Mutation' } & {
  deleteOneAuthorization?: Maybe<
    { __typename?: 'Authorization' } & Authorization_AllFragment
  >
}

export type DeleteManyAuthorizationsMutationVariables = Exact<{
  query: AuthorizationQueryInput
}>

export type DeleteManyAuthorizationsMutation = { __typename?: 'Mutation' } & {
  deleteManyAuthorizations?: Maybe<
    { __typename?: 'DeleteManyPayload' } & Pick<
      DeleteManyPayload,
      'deletedCount'
    >
  >
}

export type Group_IdentityFragment = { __typename?: 'Group' } & Pick<
  Group,
  '_id' | 'name'
>

export type Group_With_MembersFragment = { __typename?: 'Group' } & Pick<
  Group,
  'emails'
> & {
    invitations: Array<
      Maybe<
        { __typename?: 'Invitation' } & Pick<
          Invitation,
          '_id' | 'email' | 'issued' | 'viewed' | 'accepted' | 'completed'
        >
      >
    >
    users: Array<Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'email'>>>
  } & Group_IdentityFragment

export type GroupsQueryVariables = Exact<{
  query?: Maybe<GroupQueryInput>
}>

export type GroupsQuery = { __typename?: 'Query' } & {
  groups: Array<Maybe<{ __typename?: 'Group' } & Group_With_MembersFragment>>
}

export type GroupQueryVariables = Exact<{
  query: GroupQueryInput
}>

export type GroupQuery = { __typename?: 'Query' } & {
  group?: Maybe<{ __typename?: 'Group' } & Group_With_MembersFragment>
}

export type InsertOneGroupMutationVariables = Exact<{
  data: GroupInsertInput
}>

export type InsertOneGroupMutation = { __typename?: 'Mutation' } & {
  insertOneGroup?: Maybe<{ __typename?: 'Group' } & Group_IdentityFragment>
}

export type UpdateOneGroupMutationVariables = Exact<{
  query?: Maybe<GroupQueryInput>
  set: GroupUpdateInput
}>

export type UpdateOneGroupMutation = { __typename?: 'Mutation' } & {
  updateOneGroup?: Maybe<{ __typename?: 'Group' } & Group_IdentityFragment>
}

export type DeleteOneGroupMutationVariables = Exact<{
  query: GroupQueryInput
}>

export type DeleteOneGroupMutation = { __typename?: 'Mutation' } & {
  deleteOneGroup?: Maybe<{ __typename?: 'Group' } & Group_IdentityFragment>
}

export type Invitation_All_But_TokenFragment = {
  __typename?: 'Invitation'
} & Pick<
  Invitation,
  | '_id'
  | 'organization'
  | 'issued'
  | 'accepted'
  | 'viewed'
  | 'email'
  | 'admin'
  | 'completed'
  | 'counter'
> & {
    defaults?: Maybe<
      { __typename?: 'InvitationDefault' } & Pick<
        InvitationDefault,
        'firstName' | 'lastName' | 'timezone'
      >
    >
  }

export type InvitationsQueryVariables = Exact<{
  query?: Maybe<InvitationQueryInput>
  limit?: Maybe<Scalars['Int']>
  sortBy?: Maybe<InvitationSortByInput>
}>

export type InvitationsQuery = { __typename?: 'Query' } & {
  invitations: Array<
    Maybe<{ __typename?: 'Invitation' } & Invitation_All_But_TokenFragment>
  >
}

export type InvitationQueryVariables = Exact<{
  query?: Maybe<InvitationQueryInput>
}>

export type InvitationQuery = { __typename?: 'Query' } & {
  invitation?: Maybe<
    { __typename?: 'Invitation' } & Invitation_All_But_TokenFragment
  >
}

export type InvitationWithTokenQueryVariables = Exact<{
  query?: Maybe<InvitationQueryInput>
}>

export type InvitationWithTokenQuery = { __typename?: 'Query' } & {
  invitation?: Maybe<
    { __typename?: 'Invitation' } & Pick<Invitation, 'token'> &
      Invitation_All_But_TokenFragment
  >
}

export type InvitationForTokenQueryVariables = Exact<{
  input?: Maybe<InvitationForTokenInput>
}>

export type InvitationForTokenQuery = { __typename?: 'Query' } & {
  invitationForToken?: Maybe<
    { __typename?: 'Invitation' } & Pick<Invitation, 'token'> &
      Invitation_All_But_TokenFragment
  >
}

export type MyInvitationQueryVariables = Exact<{ [key: string]: never }>

export type MyInvitationQuery = { __typename?: 'Query' } & {
  myInvitation?: Maybe<
    { __typename?: 'Invitation' } & Pick<Invitation, 'token'> &
      Invitation_All_But_TokenFragment
  >
}

export type InvitationSearchQueryVariables = Exact<{
  input?: Maybe<InvitationSearchInput>
}>

export type InvitationSearchQuery = { __typename?: 'Query' } & {
  invitationSearch?: Maybe<
    Array<
      Maybe<{ __typename?: 'Invitation' } & Invitation_All_But_TokenFragment>
    >
  >
}

export type SendInvitationMutationVariables = Exact<{
  input: SendInvitationInput
}>

export type SendInvitationMutation = { __typename?: 'Mutation' } & {
  sendInvitation?: Maybe<
    { __typename?: 'Invitation' } & Invitation_All_But_TokenFragment
  >
}

export type InsertOneInvitationMutationVariables = Exact<{
  data: InvitationInsertInput
}>

export type InsertOneInvitationMutation = { __typename?: 'Mutation' } & {
  insertOneInvitation?: Maybe<
    { __typename?: 'Invitation' } & Invitation_All_But_TokenFragment
  >
}

export type InsertManyInvitationsMutationVariables = Exact<{
  data: Array<InvitationInsertInput> | InvitationInsertInput
}>

export type InsertManyInvitationsMutation = { __typename?: 'Mutation' } & {
  insertManyInvitations?: Maybe<
    { __typename?: 'InsertManyPayload' } & Pick<
      InsertManyPayload,
      'insertedIds'
    >
  >
}

export type UpdateOneInvitationMutationVariables = Exact<{
  query?: Maybe<InvitationQueryInput>
  set: InvitationUpdateInput
}>

export type UpdateOneInvitationMutation = { __typename?: 'Mutation' } & {
  updateOneInvitation?: Maybe<
    { __typename?: 'Invitation' } & Invitation_All_But_TokenFragment
  >
}

export type DeleteOneInvitationMutationVariables = Exact<{
  query: InvitationQueryInput
}>

export type DeleteOneInvitationMutation = { __typename?: 'Mutation' } & {
  deleteOneInvitation?: Maybe<
    { __typename?: 'Invitation' } & Invitation_All_But_TokenFragment
  >
}

export type DeleteManyInvitationsMutationVariables = Exact<{
  query: InvitationQueryInput
}>

export type DeleteManyInvitationsMutation = { __typename?: 'Mutation' } & {
  deleteManyInvitations?: Maybe<
    { __typename?: 'DeleteManyPayload' } & Pick<
      DeleteManyPayload,
      'deletedCount'
    >
  >
}

export type ResendInvitationEmailMutationVariables = Exact<{
  input?: Maybe<ResendInvitationEmailInput>
}>

export type ResendInvitationEmailMutation = { __typename?: 'Mutation' } & {
  resendInvitationEmail?: Maybe<
    { __typename?: 'DefaultPayload' } & Pick<DefaultPayload, 'status'>
  >
}

export type Nugget_AllFragment = { __typename?: 'Nugget' } & Pick<
  Nugget,
  | '_id'
  | 'delay'
  | 'description'
  | 'descriptionIsHtml'
  | 'image'
  | 'order'
  | 'organization'
  | 'title'
  | 'trail'
  | 'url'
>

export type GetNuggetsQueryVariables = Exact<{
  input?: Maybe<GetNuggetsInput>
}>

export type GetNuggetsQuery = { __typename?: 'Query' } & {
  getNuggets?: Maybe<
    Array<Maybe<{ __typename?: 'Nugget' } & Nugget_AllFragment>>
  >
}

export type NuggetsQueryVariables = Exact<{
  query: NuggetQueryInput
}>

export type NuggetsQuery = { __typename?: 'Query' } & {
  nuggets: Array<Maybe<{ __typename?: 'Nugget' } & Nugget_AllFragment>>
}

export type NuggetQueryVariables = Exact<{
  query: NuggetQueryInput
}>

export type NuggetQuery = { __typename?: 'Query' } & {
  nugget?: Maybe<{ __typename?: 'Nugget' } & Nugget_AllFragment>
}

export type InsertOneNuggetMutationVariables = Exact<{
  data: NuggetInsertInput
}>

export type InsertOneNuggetMutation = { __typename?: 'Mutation' } & {
  insertOneNugget?: Maybe<{ __typename?: 'Nugget' } & Nugget_AllFragment>
}

export type UpdateOneNuggetMutationVariables = Exact<{
  query?: Maybe<NuggetQueryInput>
  set: NuggetUpdateInput
}>

export type UpdateOneNuggetMutation = { __typename?: 'Mutation' } & {
  updateOneNugget?: Maybe<{ __typename?: 'Nugget' } & Nugget_AllFragment>
}

export type DeleteOneNuggetMutationVariables = Exact<{
  query: NuggetQueryInput
}>

export type DeleteOneNuggetMutation = { __typename?: 'Mutation' } & {
  deleteOneNugget?: Maybe<{ __typename?: 'Nugget' } & Nugget_AllFragment>
}

export type Organization_AllFragment = { __typename?: 'Organization' } & Pick<
  Organization,
  | '_id'
  | 'name'
  | 'operator'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'logo'
  | 'hubName'
>

export type OrganizationsQueryVariables = Exact<{
  query?: Maybe<OrganizationQueryInput>
}>

export type OrganizationsQuery = { __typename?: 'Query' } & {
  organizations: Array<
    Maybe<{ __typename?: 'Organization' } & Organization_AllFragment>
  >
}

export type OrganizationQueryVariables = Exact<{
  query: OrganizationQueryInput
}>

export type OrganizationQuery = { __typename?: 'Query' } & {
  organization?: Maybe<
    { __typename?: 'Organization' } & Organization_AllFragment
  >
}

export type InsertOneOrganizationMutationVariables = Exact<{
  data: OrganizationInsertInput
}>

export type InsertOneOrganizationMutation = { __typename?: 'Mutation' } & {
  insertOneOrganization?: Maybe<
    { __typename?: 'Organization' } & Organization_AllFragment
  >
}

export type UpdateOneOrganizationMutationVariables = Exact<{
  query?: Maybe<OrganizationQueryInput>
  set: OrganizationUpdateInput
}>

export type UpdateOneOrganizationMutation = { __typename?: 'Mutation' } & {
  updateOneOrganization?: Maybe<
    { __typename?: 'Organization' } & Organization_AllFragment
  >
}

export type Admin_Search_AllFragment = {
  __typename?: 'AdminSearchResultItem'
} & Pick<
  AdminSearchResultItem,
  '_id' | 'title' | 'description' | 'type' | 'organization' | 'trail'
>

export type User_Search_AllFragment = {
  __typename?: 'UserSearchResultItem'
} & Pick<
  UserSearchResultItem,
  '_id' | 'title' | 'description' | 'type' | 'organization' | 'trail'
>

export type AdminSearchQueryVariables = Exact<{
  query: Scalars['String']
}>

export type AdminSearchQuery = { __typename?: 'Query' } & {
  adminSearch?: Maybe<
    Array<
      Maybe<{ __typename?: 'AdminSearchResultItem' } & Admin_Search_AllFragment>
    >
  >
}

export type UserSearchQueryVariables = Exact<{
  query: Scalars['String']
}>

export type UserSearchQuery = { __typename?: 'Query' } & {
  userSearch?: Maybe<
    Array<
      Maybe<{ __typename?: 'UserSearchResultItem' } & User_Search_AllFragment>
    >
  >
}

export type Trail_AllFragment = { __typename?: 'Trail' } & Pick<
  Trail,
  | '_id'
  | 'title'
  | 'description'
  | 'image'
  | 'organization'
  | 'private'
  | 'order'
>

export type GetTrailsQueryVariables = Exact<{
  input?: Maybe<GetTrailsInput>
}>

export type GetTrailsQuery = { __typename?: 'Query' } & {
  getTrails?: Maybe<Array<Maybe<{ __typename?: 'Trail' } & Trail_AllFragment>>>
}

export type TrailsQueryVariables = Exact<{
  query: TrailQueryInput
}>

export type TrailsQuery = { __typename?: 'Query' } & {
  trails: Array<Maybe<{ __typename?: 'Trail' } & Trail_AllFragment>>
}

export type TrailQueryVariables = Exact<{
  query: TrailQueryInput
}>

export type TrailQuery = { __typename?: 'Query' } & {
  trail?: Maybe<{ __typename?: 'Trail' } & Trail_AllFragment>
}

export type InsertOneTrailMutationVariables = Exact<{
  data: TrailInsertInput
}>

export type InsertOneTrailMutation = { __typename?: 'Mutation' } & {
  insertOneTrail?: Maybe<{ __typename?: 'Trail' } & Trail_AllFragment>
}

export type UpdateOneTrailMutationVariables = Exact<{
  query?: Maybe<TrailQueryInput>
  set: TrailUpdateInput
}>

export type UpdateOneTrailMutation = { __typename?: 'Mutation' } & {
  updateOneTrail?: Maybe<{ __typename?: 'Trail' } & Trail_AllFragment>
}

export type DeleteOneTrailMutationVariables = Exact<{
  query: TrailQueryInput
}>

export type DeleteOneTrailMutation = { __typename?: 'Mutation' } & {
  deleteOneTrail?: Maybe<{ __typename?: 'Trail' } & Trail_AllFragment>
}

export type User_All_But_PassFragment = { __typename?: 'User' } & Pick<
  User,
  | '_id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'organization'
  | 'realmId'
  | 'icon'
  | 'admin'
  | 'userAgreement'
>

export type UsersQueryVariables = Exact<{
  query?: Maybe<UserQueryInput>
}>

export type UsersQuery = { __typename?: 'Query' } & {
  users: Array<Maybe<{ __typename?: 'User' } & User_All_But_PassFragment>>
}

export type UserQueryVariables = Exact<{
  query: UserQueryInput
}>

export type UserQuery = { __typename?: 'Query' } & {
  user?: Maybe<{ __typename?: 'User' } & User_All_But_PassFragment>
}

export type UpdateOneUserMutationVariables = Exact<{
  query?: Maybe<UserQueryInput>
  set: UserUpdateInput
}>

export type UpdateOneUserMutation = { __typename?: 'Mutation' } & {
  updateOneUser?: Maybe<{ __typename?: 'User' } & User_All_But_PassFragment>
}

export type DeleteUserMutationVariables = Exact<{
  query: UserQueryInput
}>

export type DeleteUserMutation = { __typename?: 'Mutation' } & {
  deleteUser?: Maybe<
    { __typename?: 'DefaultPayload' } & Pick<DefaultPayload, 'status'>
  >
}

export type AddDetailsMutationVariables = Exact<{
  input?: Maybe<InvitationDefaultInsertInput>
}>

export type AddDetailsMutation = { __typename?: 'Mutation' } & {
  addDetails?: Maybe<
    { __typename?: 'DefaultPayload' } & Pick<DefaultPayload, 'status'>
  >
}

export const Authorization_AllFragmentDoc = gql`
  fragment authorization_all on Authorization {
    _id
    email
    organization
    trail
    startTime
  }
`
export const Group_IdentityFragmentDoc = gql`
  fragment group_identity on Group {
    _id
    name
  }
`
export const Group_With_MembersFragmentDoc = gql`
  fragment group_with_members on Group {
    ...group_identity
    invitations {
      _id
      email
      issued
      viewed
      accepted
      completed
    }
    users {
      _id
      email
    }
    emails
  }
  ${Group_IdentityFragmentDoc}
`
export const Invitation_All_But_TokenFragmentDoc = gql`
  fragment invitation_all_but_token on Invitation {
    _id
    defaults {
      firstName
      lastName
      timezone
    }
    organization
    issued
    accepted
    viewed
    email
    admin
    completed
    counter
  }
`
export const Nugget_AllFragmentDoc = gql`
  fragment nugget_all on Nugget {
    _id
    delay
    description
    descriptionIsHtml
    image
    order
    organization
    title
    trail
    url
  }
`
export const Organization_AllFragmentDoc = gql`
  fragment organization_all on Organization {
    _id
    name
    operator
    primary
    secondary
    tertiary
    logo
    hubName
  }
`
export const Admin_Search_AllFragmentDoc = gql`
  fragment admin_search_all on AdminSearchResultItem {
    _id
    title
    description
    type
    organization
    trail
  }
`
export const User_Search_AllFragmentDoc = gql`
  fragment user_search_all on UserSearchResultItem {
    _id
    title
    description
    type
    organization
    trail
  }
`
export const Trail_AllFragmentDoc = gql`
  fragment trail_all on Trail {
    _id
    title
    description
    image
    organization
    private
    order
  }
`
export const User_All_But_PassFragmentDoc = gql`
  fragment user_all_but_pass on User {
    _id
    email
    firstName
    lastName
    organization
    realmId
    icon
    admin
    realmId
    userAgreement
  }
`
export const AuthorizationsDocument = gql`
  query authorizations($query: AuthorizationQueryInput!) {
    authorizations(query: $query) {
      ...authorization_all
    }
  }
  ${Authorization_AllFragmentDoc}
`
export const AuthorizationDocument = gql`
  query authorization($query: AuthorizationQueryInput!) {
    authorization(query: $query) {
      ...authorization_all
    }
  }
  ${Authorization_AllFragmentDoc}
`
export const InsertOneAuthorizationDocument = gql`
  mutation insertOneAuthorization($data: AuthorizationInsertInput!) {
    insertOneAuthorization(data: $data) {
      ...authorization_all
    }
  }
  ${Authorization_AllFragmentDoc}
`
export const InsertManyAuthorizationsDocument = gql`
  mutation insertManyAuthorizations($data: [AuthorizationInsertInput!]!) {
    insertManyAuthorizations(data: $data) {
      insertedIds
    }
  }
`
export const UpdateOneAuthorizationDocument = gql`
  mutation updateOneAuthorization(
    $query: AuthorizationQueryInput
    $set: AuthorizationUpdateInput!
  ) {
    updateOneAuthorization(query: $query, set: $set) {
      ...authorization_all
    }
  }
  ${Authorization_AllFragmentDoc}
`
export const DeleteOneAuthorizationDocument = gql`
  mutation deleteOneAuthorization($query: AuthorizationQueryInput!) {
    deleteOneAuthorization(query: $query) {
      ...authorization_all
    }
  }
  ${Authorization_AllFragmentDoc}
`
export const DeleteManyAuthorizationsDocument = gql`
  mutation deleteManyAuthorizations($query: AuthorizationQueryInput!) {
    deleteManyAuthorizations(query: $query) {
      deletedCount
    }
  }
`
export const GroupsDocument = gql`
  query groups($query: GroupQueryInput) {
    groups(query: $query) {
      ...group_with_members
    }
  }
  ${Group_With_MembersFragmentDoc}
`
export const GroupDocument = gql`
  query group($query: GroupQueryInput!) {
    group(query: $query) {
      ...group_with_members
    }
  }
  ${Group_With_MembersFragmentDoc}
`
export const InsertOneGroupDocument = gql`
  mutation insertOneGroup($data: GroupInsertInput!) {
    insertOneGroup(data: $data) {
      ...group_identity
    }
  }
  ${Group_IdentityFragmentDoc}
`
export const UpdateOneGroupDocument = gql`
  mutation updateOneGroup($query: GroupQueryInput, $set: GroupUpdateInput!) {
    updateOneGroup(query: $query, set: $set) {
      ...group_identity
    }
  }
  ${Group_IdentityFragmentDoc}
`
export const DeleteOneGroupDocument = gql`
  mutation deleteOneGroup($query: GroupQueryInput!) {
    deleteOneGroup(query: $query) {
      ...group_identity
    }
  }
  ${Group_IdentityFragmentDoc}
`
export const InvitationsDocument = gql`
  query invitations(
    $query: InvitationQueryInput
    $limit: Int
    $sortBy: InvitationSortByInput
  ) {
    invitations(query: $query, limit: $limit, sortBy: $sortBy) {
      ...invitation_all_but_token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const InvitationDocument = gql`
  query invitation($query: InvitationQueryInput) {
    invitation(query: $query) {
      ...invitation_all_but_token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const InvitationWithTokenDocument = gql`
  query invitationWithToken($query: InvitationQueryInput) {
    invitation(query: $query) {
      ...invitation_all_but_token
      token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const InvitationForTokenDocument = gql`
  query invitationForToken($input: InvitationForTokenInput) {
    invitationForToken(input: $input) {
      ...invitation_all_but_token
      token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const MyInvitationDocument = gql`
  query myInvitation {
    myInvitation {
      ...invitation_all_but_token
      token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const InvitationSearchDocument = gql`
  query invitationSearch($input: InvitationSearchInput) {
    invitationSearch(input: $input) {
      ...invitation_all_but_token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const SendInvitationDocument = gql`
  mutation sendInvitation($input: SendInvitationInput!) {
    sendInvitation(input: $input) {
      ...invitation_all_but_token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const InsertOneInvitationDocument = gql`
  mutation insertOneInvitation($data: InvitationInsertInput!) {
    insertOneInvitation(data: $data) {
      ...invitation_all_but_token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const InsertManyInvitationsDocument = gql`
  mutation insertManyInvitations($data: [InvitationInsertInput!]!) {
    insertManyInvitations(data: $data) {
      insertedIds
    }
  }
`
export const UpdateOneInvitationDocument = gql`
  mutation updateOneInvitation(
    $query: InvitationQueryInput
    $set: InvitationUpdateInput!
  ) {
    updateOneInvitation(query: $query, set: $set) {
      ...invitation_all_but_token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const DeleteOneInvitationDocument = gql`
  mutation deleteOneInvitation($query: InvitationQueryInput!) {
    deleteOneInvitation(query: $query) {
      ...invitation_all_but_token
    }
  }
  ${Invitation_All_But_TokenFragmentDoc}
`
export const DeleteManyInvitationsDocument = gql`
  mutation deleteManyInvitations($query: InvitationQueryInput!) {
    deleteManyInvitations(query: $query) {
      deletedCount
    }
  }
`
export const ResendInvitationEmailDocument = gql`
  mutation resendInvitationEmail($input: ResendInvitationEmailInput) {
    resendInvitationEmail(input: $input) {
      status
    }
  }
`
export const GetNuggetsDocument = gql`
  query getNuggets($input: GetNuggetsInput) {
    getNuggets(input: $input) {
      ...nugget_all
    }
  }
  ${Nugget_AllFragmentDoc}
`
export const NuggetsDocument = gql`
  query nuggets($query: NuggetQueryInput!) {
    nuggets(query: $query) {
      ...nugget_all
    }
  }
  ${Nugget_AllFragmentDoc}
`
export const NuggetDocument = gql`
  query nugget($query: NuggetQueryInput!) {
    nugget(query: $query) {
      ...nugget_all
    }
  }
  ${Nugget_AllFragmentDoc}
`
export const InsertOneNuggetDocument = gql`
  mutation insertOneNugget($data: NuggetInsertInput!) {
    insertOneNugget(data: $data) {
      ...nugget_all
    }
  }
  ${Nugget_AllFragmentDoc}
`
export const UpdateOneNuggetDocument = gql`
  mutation updateOneNugget($query: NuggetQueryInput, $set: NuggetUpdateInput!) {
    updateOneNugget(query: $query, set: $set) {
      ...nugget_all
    }
  }
  ${Nugget_AllFragmentDoc}
`
export const DeleteOneNuggetDocument = gql`
  mutation deleteOneNugget($query: NuggetQueryInput!) {
    deleteOneNugget(query: $query) {
      ...nugget_all
    }
  }
  ${Nugget_AllFragmentDoc}
`
export const OrganizationsDocument = gql`
  query organizations($query: OrganizationQueryInput) {
    organizations(query: $query) {
      ...organization_all
    }
  }
  ${Organization_AllFragmentDoc}
`
export const OrganizationDocument = gql`
  query organization($query: OrganizationQueryInput!) {
    organization(query: $query) {
      ...organization_all
    }
  }
  ${Organization_AllFragmentDoc}
`
export const InsertOneOrganizationDocument = gql`
  mutation insertOneOrganization($data: OrganizationInsertInput!) {
    insertOneOrganization(data: $data) {
      ...organization_all
    }
  }
  ${Organization_AllFragmentDoc}
`
export const UpdateOneOrganizationDocument = gql`
  mutation updateOneOrganization(
    $query: OrganizationQueryInput
    $set: OrganizationUpdateInput!
  ) {
    updateOneOrganization(query: $query, set: $set) {
      ...organization_all
    }
  }
  ${Organization_AllFragmentDoc}
`
export const AdminSearchDocument = gql`
  query adminSearch($query: String!) {
    adminSearch(input: { query: $query }) {
      ...admin_search_all
    }
  }
  ${Admin_Search_AllFragmentDoc}
`
export const UserSearchDocument = gql`
  query userSearch($query: String!) {
    userSearch(input: { query: $query }) {
      ...user_search_all
    }
  }
  ${User_Search_AllFragmentDoc}
`
export const GetTrailsDocument = gql`
  query getTrails($input: GetTrailsInput) {
    getTrails(input: $input) {
      ...trail_all
    }
  }
  ${Trail_AllFragmentDoc}
`
export const TrailsDocument = gql`
  query trails($query: TrailQueryInput!) {
    trails(query: $query) {
      ...trail_all
    }
  }
  ${Trail_AllFragmentDoc}
`
export const TrailDocument = gql`
  query trail($query: TrailQueryInput!) {
    trail(query: $query) {
      ...trail_all
    }
  }
  ${Trail_AllFragmentDoc}
`
export const InsertOneTrailDocument = gql`
  mutation insertOneTrail($data: TrailInsertInput!) {
    insertOneTrail(data: $data) {
      ...trail_all
    }
  }
  ${Trail_AllFragmentDoc}
`
export const UpdateOneTrailDocument = gql`
  mutation updateOneTrail($query: TrailQueryInput, $set: TrailUpdateInput!) {
    updateOneTrail(query: $query, set: $set) {
      ...trail_all
    }
  }
  ${Trail_AllFragmentDoc}
`
export const DeleteOneTrailDocument = gql`
  mutation deleteOneTrail($query: TrailQueryInput!) {
    deleteOneTrail(query: $query) {
      ...trail_all
    }
  }
  ${Trail_AllFragmentDoc}
`
export const UsersDocument = gql`
  query users($query: UserQueryInput) {
    users(query: $query) {
      ...user_all_but_pass
    }
  }
  ${User_All_But_PassFragmentDoc}
`
export const UserDocument = gql`
  query user($query: UserQueryInput!) {
    user(query: $query) {
      ...user_all_but_pass
    }
  }
  ${User_All_But_PassFragmentDoc}
`
export const UpdateOneUserDocument = gql`
  mutation updateOneUser($query: UserQueryInput, $set: UserUpdateInput!) {
    updateOneUser(query: $query, set: $set) {
      ...user_all_but_pass
    }
  }
  ${User_All_But_PassFragmentDoc}
`
export const DeleteUserDocument = gql`
  mutation deleteUser($query: UserQueryInput!) {
    deleteUser(input: $query) {
      status
    }
  }
`
export const AddDetailsDocument = gql`
  mutation addDetails($input: InvitationDefaultInsertInput) {
    addDetails(input: $input) {
      status
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    authorizations(
      variables: AuthorizationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AuthorizationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AuthorizationsQuery>(
            AuthorizationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'authorizations'
      )
    },
    authorization(
      variables: AuthorizationQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AuthorizationQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AuthorizationQuery>(AuthorizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'authorization'
      )
    },
    insertOneAuthorization(
      variables: InsertOneAuthorizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertOneAuthorizationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertOneAuthorizationMutation>(
            InsertOneAuthorizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertOneAuthorization'
      )
    },
    insertManyAuthorizations(
      variables: InsertManyAuthorizationsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertManyAuthorizationsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertManyAuthorizationsMutation>(
            InsertManyAuthorizationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertManyAuthorizations'
      )
    },
    updateOneAuthorization(
      variables: UpdateOneAuthorizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateOneAuthorizationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneAuthorizationMutation>(
            UpdateOneAuthorizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneAuthorization'
      )
    },
    deleteOneAuthorization(
      variables: DeleteOneAuthorizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteOneAuthorizationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteOneAuthorizationMutation>(
            DeleteOneAuthorizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteOneAuthorization'
      )
    },
    deleteManyAuthorizations(
      variables: DeleteManyAuthorizationsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteManyAuthorizationsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteManyAuthorizationsMutation>(
            DeleteManyAuthorizationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteManyAuthorizations'
      )
    },
    groups(
      variables?: GroupsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GroupsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GroupsQuery>(GroupsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'groups'
      )
    },
    group(
      variables: GroupQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GroupQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GroupQuery>(GroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'group'
      )
    },
    insertOneGroup(
      variables: InsertOneGroupMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertOneGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertOneGroupMutation>(
            InsertOneGroupDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertOneGroup'
      )
    },
    updateOneGroup(
      variables: UpdateOneGroupMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateOneGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneGroupMutation>(
            UpdateOneGroupDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneGroup'
      )
    },
    deleteOneGroup(
      variables: DeleteOneGroupMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteOneGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteOneGroupMutation>(
            DeleteOneGroupDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteOneGroup'
      )
    },
    invitations(
      variables?: InvitationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvitationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InvitationsQuery>(InvitationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'invitations'
      )
    },
    invitation(
      variables?: InvitationQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvitationQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InvitationQuery>(InvitationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'invitation'
      )
    },
    invitationWithToken(
      variables?: InvitationWithTokenQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvitationWithTokenQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InvitationWithTokenQuery>(
            InvitationWithTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'invitationWithToken'
      )
    },
    invitationForToken(
      variables?: InvitationForTokenQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvitationForTokenQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InvitationForTokenQuery>(
            InvitationForTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'invitationForToken'
      )
    },
    myInvitation(
      variables?: MyInvitationQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<MyInvitationQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<MyInvitationQuery>(MyInvitationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'myInvitation'
      )
    },
    invitationSearch(
      variables?: InvitationSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InvitationSearchQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InvitationSearchQuery>(
            InvitationSearchDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'invitationSearch'
      )
    },
    sendInvitation(
      variables: SendInvitationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SendInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SendInvitationMutation>(
            SendInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'sendInvitation'
      )
    },
    insertOneInvitation(
      variables: InsertOneInvitationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertOneInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertOneInvitationMutation>(
            InsertOneInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertOneInvitation'
      )
    },
    insertManyInvitations(
      variables: InsertManyInvitationsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertManyInvitationsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertManyInvitationsMutation>(
            InsertManyInvitationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertManyInvitations'
      )
    },
    updateOneInvitation(
      variables: UpdateOneInvitationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateOneInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneInvitationMutation>(
            UpdateOneInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneInvitation'
      )
    },
    deleteOneInvitation(
      variables: DeleteOneInvitationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteOneInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteOneInvitationMutation>(
            DeleteOneInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteOneInvitation'
      )
    },
    deleteManyInvitations(
      variables: DeleteManyInvitationsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteManyInvitationsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteManyInvitationsMutation>(
            DeleteManyInvitationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteManyInvitations'
      )
    },
    resendInvitationEmail(
      variables?: ResendInvitationEmailMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ResendInvitationEmailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ResendInvitationEmailMutation>(
            ResendInvitationEmailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'resendInvitationEmail'
      )
    },
    getNuggets(
      variables?: GetNuggetsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetNuggetsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetNuggetsQuery>(GetNuggetsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getNuggets'
      )
    },
    nuggets(
      variables: NuggetsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<NuggetsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<NuggetsQuery>(NuggetsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'nuggets'
      )
    },
    nugget(
      variables: NuggetQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<NuggetQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<NuggetQuery>(NuggetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'nugget'
      )
    },
    insertOneNugget(
      variables: InsertOneNuggetMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertOneNuggetMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertOneNuggetMutation>(
            InsertOneNuggetDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertOneNugget'
      )
    },
    updateOneNugget(
      variables: UpdateOneNuggetMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateOneNuggetMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneNuggetMutation>(
            UpdateOneNuggetDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneNugget'
      )
    },
    deleteOneNugget(
      variables: DeleteOneNuggetMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteOneNuggetMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteOneNuggetMutation>(
            DeleteOneNuggetDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteOneNugget'
      )
    },
    organizations(
      variables?: OrganizationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<OrganizationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<OrganizationsQuery>(OrganizationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'organizations'
      )
    },
    organization(
      variables: OrganizationQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<OrganizationQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<OrganizationQuery>(OrganizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'organization'
      )
    },
    insertOneOrganization(
      variables: InsertOneOrganizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertOneOrganizationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertOneOrganizationMutation>(
            InsertOneOrganizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertOneOrganization'
      )
    },
    updateOneOrganization(
      variables: UpdateOneOrganizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateOneOrganizationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneOrganizationMutation>(
            UpdateOneOrganizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneOrganization'
      )
    },
    adminSearch(
      variables: AdminSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AdminSearchQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AdminSearchQuery>(AdminSearchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'adminSearch'
      )
    },
    userSearch(
      variables: UserSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserSearchQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UserSearchQuery>(UserSearchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'userSearch'
      )
    },
    getTrails(
      variables?: GetTrailsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetTrailsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTrailsQuery>(GetTrailsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getTrails'
      )
    },
    trails(
      variables: TrailsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TrailsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TrailsQuery>(TrailsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'trails'
      )
    },
    trail(
      variables: TrailQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TrailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TrailQuery>(TrailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'trail'
      )
    },
    insertOneTrail(
      variables: InsertOneTrailMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<InsertOneTrailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertOneTrailMutation>(
            InsertOneTrailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'insertOneTrail'
      )
    },
    updateOneTrail(
      variables: UpdateOneTrailMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateOneTrailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneTrailMutation>(
            UpdateOneTrailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneTrail'
      )
    },
    deleteOneTrail(
      variables: DeleteOneTrailMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteOneTrailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteOneTrailMutation>(
            DeleteOneTrailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteOneTrail'
      )
    },
    users(
      variables?: UsersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UsersQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UsersQuery>(UsersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'users'
      )
    },
    user(
      variables: UserQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UserQuery>(UserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'user'
      )
    },
    updateOneUser(
      variables: UpdateOneUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateOneUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneUserMutation>(
            UpdateOneUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneUser'
      )
    },
    deleteUser(
      variables: DeleteUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteUserMutation>(DeleteUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteUser'
      )
    },
    addDetails(
      variables?: AddDetailsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AddDetailsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddDetailsMutation>(AddDetailsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addDetails'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
