import React, { FC, ReactElement } from 'react'
import './flipper.scss'

export type FlipperProps = {
  front: ReactElement
  back: ReactElement
  width: string
  height: string
  flip: boolean
  className?: string
}

const Flipper: FC<FlipperProps> = props => {
  const { front, back, flip, width, height, className } = props
  return (
    <div
      className={`flipper ${flip ? 'flip' : ''} ${className ?? ''}`}
      style={{ width, height }}
    >
      {front}
      <div style={{ transform: 'rotateY(180deg)' }}>{back}</div>
    </div>
  )
}
export default Flipper
