import {
  Organization,
  UpdateOneOrganizationMutationVariables,
} from '../../../graphql/sdk.generated'
import { MutationFunction, useMutation } from 'react-query'
import { organizationQueryKey } from './useOrganization'
import { queryClient, sdk } from 'src/components'
import { handleSuccess } from '../../../util/handle-success'

export type UpdateOrganizationMutationInput =
  UpdateOneOrganizationMutationVariables['set']
export type UpdateOrganizationMutationRes = Organization

export const updateOrganizationMutationFn = (
  _id: string
): MutationFunction<
  UpdateOrganizationMutationRes,
  UpdateOrganizationMutationInput
> => {
  return set => {
    return sdk
      .updateOneOrganization({ query: { _id }, set })
      .then(
        ({ updateOneOrganization }) => updateOneOrganization
      ) as Promise<UpdateOrganizationMutationRes>
  }
}

export const useUpdateOrganizationMutation = (_id: string) => {
  return useMutation<
    UpdateOrganizationMutationRes,
    Error,
    UpdateOrganizationMutationInput,
    void
  >(updateOrganizationMutationFn(_id), {
    onSuccess: organization => {
      handleSuccess('Updated organization')
      queryClient.setQueryData(organizationQueryKey(_id), organization)
    },
    onError: () => {
      queryClient.invalidateQueries(organizationQueryKey(_id)).then() // Dangle
    },
  })
}
