import { MutationFunction, useMutation } from 'react-query'
import { sdk } from 'src/components'
import { handleSuccess } from 'src/core/util/handle-success'
import { handleRecoverableError } from 'src/core/util/handle-recoverable-error'
import { refreshUsersForOrg } from './useUsersForOrg'

export type DeleteUserMutationInput = string
export type DeleteUserMutationRes = void

export const deleteUserMutationFn =
  (_id: DeleteUserMutationInput): MutationFunction<DeleteUserMutationRes> =>
  () => {
    return sdk
      .deleteUser({ query: { _id } })
      .then(({ deleteUser }) => deleteUser) as Promise<DeleteUserMutationRes>
  }

const errMsg = 'Could not delete user'

export const useDeleteUserMutation = (input: DeleteUserMutationInput) =>
  useMutation<DeleteUserMutationRes, Error, void, void>(
    deleteUserMutationFn(input),
    {
      onSuccess: () => {
        handleSuccess('Deleted user')
        refreshUsersForOrg(input).then() // Dangle
      },
      onError: error => handleRecoverableError(errMsg, error),
    }
  )
