import { useQuery } from 'react-query'
import { QueryFunction } from 'react-query/types/core/types'
import { handleRes } from '../handle-res'
import { Nugget } from 'src/core/graphql/graphql.generated'
import { queryClient, sdk } from 'src/components'
import { byOrderThenDelay } from '../../../util/sorting'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'

export type ThingsForBundleQueryRes = Nugget[]
export type ThingsForBundleQueryKey = ['thingsForSelectedBundle', string]

const thingsForBundleQueryKey = (bundle: string): ThingsForBundleQueryKey => [
  'thingsForSelectedBundle',
  bundle,
]
const thingsQueryFn =
  (): QueryFunction<ThingsForBundleQueryRes, ThingsForBundleQueryKey> =>
  ({ queryKey: [, trail] }): Promise<ThingsForBundleQueryRes> => {
    return sdk
      .nuggets({ query: { trail } })
      .then(({ nuggets }) => nuggets as Nugget[])
      .then(nuggets =>
        nuggets.sort(byOrderThenDelay)
      ) as Promise<ThingsForBundleQueryRes>
  }

const errMsg = 'Could not get things for bundle'

export const useThingsForBundle = (bundle: string): ThingsForBundleQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const queryKey = thingsForBundleQueryKey(bundle)
  const res = useQuery(queryKey, thingsQueryFn(), options)
  return handleRes(res, errMsg)
}

export const refreshThingsForBundle = (bundle: string) =>
  queryClient.invalidateQueries(thingsForBundleQueryKey(bundle))
