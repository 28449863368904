import { Organization } from './graphql/types.generated'
import * as R from 'ramda'

export const brandingKeys = [
  'primary',
  'secondary',
  'tertiary',
  'logo',
  'largeLogo',
] as const
export type MaybeBranding = Pick<Organization, typeof brandingKeys[number]>
export type Branding = {
  primary: string
  secondary: string
  tertiary: string
  logo: string
  largeLogo: string
}

export const khhBranding: Branding = {
  primary: '#054372',
  secondary: '#1C75BC',
  tertiary: '#00B4EF',
  logo: '/logos/khh-small.svg',
  largeLogo: '/logos/khh-full.svg',
}

export const updateBranding = (organization?: Organization) => {
  const branding = orgBrandingOrKhhDefaults(organization)
  const root = document.documentElement
  root.style.setProperty('--primary', branding.primary)
  root.style.setProperty('--primary-soft', branding.primary + '10')
  root.style.setProperty('--secondary', branding.secondary)
}

export const orgBrandingOrKhhDefaults = (
  organization: Organization | null | undefined
): Branding => {
  if (!organization) return khhBranding
  return R.mergeRight(khhBranding)(R.pick(brandingKeys, organization))
}
