import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import './card.scss'
import sanitizeHtml from 'sanitize-html'
import ThingOrBundleImage from '../ThingOrBundleImage'

export type CardItem = {
  _id: string
  title: string
  description: string
  url?: string | null
  badges?: BadgeProps[]
  image?: string | null
  buttons?: ReactNode
}

export type BadgeProps = {
  label: string
} & (
  | {
      color: string
      softColor: string
    }
  | {
      themeColor: 'primary' | 'secondary' | 'tertiary'
    }
)

export interface ListItemProps<T> {
  item: T
  isSelected: boolean
  isHighlighted: boolean
  onClick: (t: T) => void
  goToUrl?: (t: T) => void
  key: string
  cardTag?: string
}

export const Badge: FC<BadgeProps> = props => {
  const { label } = props

  let color, softColor
  if ('themeColor' in props)
    (color = `var(--${props.themeColor})`),
      (softColor = `var(--${props.themeColor}-soft)`)
  else (color = props.color), (softColor = props.softColor)

  return (
    <div
      style={{ backgroundColor: softColor, borderColor: color, color }}
      className='card-badge'
    >
      {label}
    </div>
  )
}

const forbidden = ['a']
const allowedTags = sanitizeHtml.defaults.allowedTags.filter(
  tag => !forbidden.includes(tag)
)
const useSanitizedHtml = (html: string): string => {
  return useMemo(() => {
    return sanitizeHtml(html, {
      allowedTags,
    })
  }, [html])
}

const Card: <T extends CardItem>(props: ListItemProps<T>) => ReactElement =
  props => {
    const thisRef = useRef<HTMLDivElement>(null)
    const { item, onClick, goToUrl, cardTag, isSelected, isHighlighted } = props
    const { title, description, badges, buttons } = item

    const sanitizedDescription = useSanitizedHtml(description)

    return (
      <div
        data-cy={`flat-card:${title}`}
        data-card-tag={cardTag}
        data-searched={isHighlighted}
        key={item._id}
        className={`card shadow-md ${
          isHighlighted ? 'searched' : isSelected ? 'selected' : 'normal'
        }`}
        ref={thisRef}
        onClick={() => onClick(item)}
      >
        <div className='flex'>
          {item.image && <ThingOrBundleImage imageId={item.image} />}
          <div
            className={`card-text ${item.url && goToUrl ? 'has-url' : ''} ${
              item.image ? 'with-image' : ''
            }`}
          >
            <div className='flex flex-col font-medium'>
              {badges && badges.length > 0 && (
                <ul className='card-badges'>
                  {badges.map(badge => (
                    <Badge key={badge.label} {...badge} />
                  ))}
                </ul>
              )}
              <div className='flex flex-row space-x-2 items-center'>
                <p className='title'>{title}</p>
                {item.url && <div style={{ color: 'var(--secondary)' }}>➤</div>}
              </div>
            </div>
            <div
              className='description'
              dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            />
          </div>
        </div>
        {buttons && <div className='flex p-2 gap-2 bg-gray-200'>{buttons}</div>}
      </div>
    )
  }

export default Card
