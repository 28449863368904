import { Organization } from '../../../graphql/sdk.generated'
import { useQuery } from 'react-query'
import { QueryFunction } from 'react-query/types/core/types'
import { handleRes } from '../handle-res'
import { sdk } from 'src/components'
import { recoverableErrorHandler } from 'src/core/util/handle-recoverable-error'
export type OrganizationQueryKey = ReturnType<typeof organizationQueryKey>
export type OrganizationQueryRes = Organization

export const organizationQueryKey = (orgId: string | null) => [
  'organization',
  orgId,
]
export const organizationQueryFn =
  (): QueryFunction<OrganizationQueryRes, OrganizationQueryKey> =>
  ({ queryKey: [, _id] }) => {
    return sdk
      .organization({ query: { _id } })
      .then(({ organization }) => organization) as Promise<OrganizationQueryRes>
  }

const errMsg = 'Could not get organization'

export const useOrganization = (_id: string): OrganizationQueryRes => {
  const options = { onError: recoverableErrorHandler(errMsg) }
  const queryKey = organizationQueryKey(_id)
  const queryFn = organizationQueryFn()
  const res = useQuery(queryKey, queryFn, options)
  return handleRes(res, errMsg)
}
