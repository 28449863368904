import { Trail, TrailInsertInput } from 'src/core/graphql/sdk.generated'
import { MutationFunction, useMutation } from 'react-query'
import { handleSuccess } from 'src/core/util/handle-success'
import { recoverableErrorHandler } from 'src/core/util/handle-recoverable-error'
import { refreshBundlesForOrg } from './useBundlesForOrg'
import { queryClient, sdk, useSelectedOrgOrThrow } from 'src/components'
import { bundleQueryKey, BundleQueryRes } from './useBundle'

export type InsertBundleMutationInput = TrailInsertInput
export type InsertBundleMutationRes = Trail
export const insertBundleMutationFn = (): MutationFunction<
  InsertBundleMutationRes,
  InsertBundleMutationInput
> => {
  return data => {
    return sdk
      .insertOneTrail({ data })
      .then(
        ({ insertOneTrail }) => insertOneTrail
      ) as Promise<InsertBundleMutationRes>
  }
}

const errMsg = 'Could not create bundle'

export const useInsertBundleMutation = () => {
  const selectedOrg = useSelectedOrgOrThrow()
  return useMutation<
    InsertBundleMutationRes,
    Error,
    InsertBundleMutationInput,
    void
  >(insertBundleMutationFn(), {
    onSuccess: data => {
      handleSuccess('Created bundle "' + data.title + '"')
      queryClient.setQueryData<BundleQueryRes>(bundleQueryKey(data._id), data)
    },
    onError: recoverableErrorHandler(errMsg),
    onSettled: () => refreshBundlesForOrg(selectedOrg._id),
  })
}
