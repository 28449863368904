import React, { ReactElement, useState } from 'react'
import { FormDataBase } from '../../form'
import Input, { InputLabel, InputProps, InputState } from '../Input'
import { Path, useFormContext, useWatch } from 'react-hook-form'
import '../Input/input.scss'
import './passwordInput.scss'
import Eye from '../../icons/Eye'
import EyeOff from '../../icons/EyeOff'
import Flipper from '../Flipper'
import { AnimatePresence, motion } from 'framer-motion'

const PasswordFlavor = <FD extends FormDataBase>(
  props: PasswordInputProps<FD> & InputState
): ReactElement => {
  const { label } = props
  const { register, control } = useFormContext<FD>()
  const watch = useWatch<FD>({ control }) as unknown as Partial<FD>
  const [show, setShow] = useState<boolean>(false)
  const value: string = (watch['new-password'] ?? '') as string

  const blank: boolean = value === ''
  const valid = value.length >= 8 && value.length <= 64

  return (
    <div
      className={`password-input ${
        valid ? 'valid' : blank ? 'blank' : 'unmet'
      }`}
    >
      <div className={'input-row'}>
        <InputLabel {...props} label='New Password' name='new-password' />
        <div className='flex flex-row space-between'>
          <input
            id='new-password'
            key={label}
            type={show ? 'text' : 'password'}
            autoComplete='new-password'
            minLength={8}
            maxLength={64}
            {...register('new-password' as Path<FD>, {
              required: true,
            })}
          />
          <button
            id='toggle-password'
            type='button'
            style={{ color: 'var(--color-gray-500)' }}
            aria-label='Show password as plain text. Warning: this will display your password on the screen.'
            onClick={() => setShow(!show)}
          >
            <Flipper
              width='1rem'
              height='1.5rem'
              front={<Eye />}
              back={<EyeOff />}
              flip={show}
            />
          </button>
        </div>
      </div>
      <AnimatePresence>
        {!blank && !valid && (
          <motion.span
            id='password-length-hint'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: { delay: 0.3, duration: 0.3 },
            }}
          >
            Must be 8 to 64 characters long
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  )
}

export type PasswordInputProps<FD extends FormDataBase> = Omit<
  InputProps<FD>,
  'name' | 'type'
>
const PasswordInput = <FD extends FormDataBase & { password: string }>(
  props: PasswordInputProps<FD>
): ReactElement => {
  return (
    <Input<FD, { name: Path<FD>; type: 'password' }>
      {...{
        ...props,
        name: 'new-password' as Path<FD>,
        type: 'password',
        Flavor: PasswordFlavor,
      }}
    />
  )
}
export default PasswordInput
