import { Branding, orgBrandingOrKhhDefaults, updateBranding } from '../branding'
import { useSelectedOrg } from '../../components/OrganizationSwitcherModal'
import { useEffect } from 'react'

const useBranding: () => Branding = () => {
  const selectedOrg = useSelectedOrg() ?? undefined
  return orgBrandingOrKhhDefaults(selectedOrg)
}

export default useBranding
