import React, { FC, ReactElement, VFC } from 'react'
import './ErrorComponent.css'
import * as Sentry from '@sentry/react'
import { FallbackRender } from '@sentry/react/types/errorboundary'
import { NotFoundError } from '../../core/errors'
import { useLocation, useNavigate } from 'react-router-dom'

const Exclamation: VFC = () => (
  <svg
    className='exclamation'
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
      clipRule='evenodd'
    />
  </svg>
)

const ErrorComponent: FallbackRender = ({
  resetError,
  error,
  eventId,
}): ReactElement => {
  const isLearner = window.location.pathname.includes('/learner')
  const goHome = () =>
    (window.location.pathname = isLearner ? '/learner' : '/teacher')

  if (error instanceof NotFoundError)
    return (
      <>
        <div className='learner-dashboard-error'>
          <Exclamation />
          <div className='text-center'>
            <p>Whoops! We couldn't find that.</p>
            <p className='bug-report' onClick={goHome}>
              Click here to go home
            </p>
            <p className='bug-report' onClick={Sentry.showReportDialog}>
              Click here to submit a bug report
            </p>
            <span className='error-id'>Event ID: {eventId}</span>
          </div>
        </div>
      </>
    )

  return (
    <>
      <div className='learner-dashboard-error'>
        <Exclamation />
        <div className='text-center'>
          <p>Whoops! Something went wrong.</p>
          <p className='bug-report' onClick={resetError}>
            Click here to try again
          </p>
          <p className='bug-report' onClick={Sentry.showReportDialog}>
            Click here to submit a bug report
          </p>
          <span className='error-id'>Event ID: {eventId}</span>
        </div>
      </div>
    </>
  )
}

export default ErrorComponent
