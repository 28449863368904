import React, { ReactElement } from 'react'
import './booleanInput.scss'
import { Path, useFormContext, useWatch } from 'react-hook-form'
import { FormDataBase } from '../../form'
import Input, { InputProps } from '../Input'
import Flipper from '../Flipper'
import { UnpackNestedValue } from 'react-hook-form/dist/types/form'
import { FieldPathValue } from 'react-hook-form'
import CheckFrame from '../../icons/CheckFrame'
import Check from '../../icons/Check'

const BooleanFlavor = <FD extends FormDataBase>(
  props: InputProps<FD>
): ReactElement => {
  const { name, label, disabled } = props
  const { setValue, register, control } = useFormContext<FD>()
  const value = Boolean(useWatch({ control })[name])

  return (
    <div
      className='row boolean-input'
      onClick={() => {
        if (!disabled)
          setValue<Path<FD>>(
            name,
            !value as UnpackNestedValue<FieldPathValue<FD, typeof name>>,
            { shouldDirty: true }
          )
      }}
    >
      <input
        {...register(name)}
        type='checkbox'
        id={name as string}
        name={name as string}
        value={label}
      />
      <Flipper
        front={<CheckFrame />}
        back={<Check />}
        width='16px'
        height='16px'
        flip={value}
      />
      <p className='label-text'>{label}</p>
    </div>
  )
}

export type BooleanInputProps<FD extends FormDataBase> = Omit<
  InputProps<FD>,
  'type'
>

const BooleanInput = <FD extends FormDataBase>(
  props: BooleanInputProps<FD>
): ReactElement => (
  <Input<FD> {...{ ...props, type: 'checkbox', Flavor: BooleanFlavor }} />
)
export default BooleanInput
