import React, { FC, ReactElement, Suspense, VFC } from 'react'
import { FormDataBase } from '../../form'
import { TimezoneInputProps } from './loadable'

const LoadingTimezoneInput: VFC = () => <div className='loading-timezone' />

const TimezoneInput = <FD extends FormDataBase & { timezone: string }>(
  props: TimezoneInputProps<FD>
): ReactElement => {
  const LoadedTimezoneInput = React.lazy<FC<TimezoneInputProps<FD>>>(
    () => import('./loadable')
  )
  return (
    <Suspense fallback={<LoadingTimezoneInput />}>
      <LoadedTimezoneInput {...props} />
    </Suspense>
  )
}

export default TimezoneInput
