import {
  Nugget,
  UpdateOneNuggetMutationVariables,
} from '../../../graphql/sdk.generated'
import { MutationFunction, useMutation } from 'react-query'
import { thingQueryKey, ThingQueryRes } from './useThing'
import { refreshThingsForBundle } from './useThingsForBundle'
import { queryClient, sdk } from '../../../../components'
import { useSelectedBundleIdOrThrow } from 'src/core/hooks/params/bundles'
import { handleSuccess } from '../../../util/handle-success'
import { handleRecoverableError } from '../../../util/handle-recoverable-error'

export type UpdateThingMutationInput = UpdateOneNuggetMutationVariables['set']
export type UpdateThingMutationRes = Nugget
export const updateThingMutationFn = (
  _id: string
): MutationFunction<UpdateThingMutationRes, UpdateThingMutationInput> => {
  return set => {
    return sdk
      .updateOneNugget({ query: { _id }, set })
      .then(
        ({ updateOneNugget }) => updateOneNugget
      ) as Promise<UpdateThingMutationRes>
  }
}

const errMsg = 'Could not update thing'

export const useUpdateThingMutation = (_id: string) => {
  const selectedBundleId = useSelectedBundleIdOrThrow()
  return useMutation<
    UpdateThingMutationRes,
    Error,
    UpdateThingMutationInput,
    void
  >(updateThingMutationFn(_id), {
    onSuccess: data => {
      handleSuccess('Updated thing')
      queryClient.setQueryData<ThingQueryRes>(thingQueryKey(_id), data)
      refreshThingsForBundle(selectedBundleId).then() // Dangle
    },
    onError: error => handleRecoverableError(errMsg, error),
    onSettled: () => queryClient.invalidateQueries(thingQueryKey(_id)).then(),
  })
}
