import React, { FC, ReactElement, ReactNode, useRef, VFC } from 'react'
import PullToRefresh from 'rmc-pull-to-refresh'
import { CardItem } from '../Card'
import './list.css'
import 'rmc-pull-to-refresh/assets/index.css'
import InlineList from '../InlineList'

export interface ListProps<T> {
  items: T[]
  descriptionFunc?: (t: T) => ReactElement
  refresh: () => Promise<unknown | void>
  onClick: (t: T) => void
  goToUrl?: (t: T) => void
  selected: T | undefined
  highlighted: T | undefined
  Footer?: FC
  Action?: FC
  placeholder: string
}

function List<T extends CardItem>(
  props: ListProps<T>
): ReactElement<ListProps<T>> {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={containerRef} className='w-full h-full overflow-y-scroll'>
      <InlineList {...props} />
    </div>
  )
}

export default List
