import React, { FC } from 'react'
import './button.scss'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'

export type ButtonProps = {
  id?: string
  isLoading?: boolean
  label: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  rank?: 'pane' | 'primary' | 'secondary' | 'tertiary'
  destructive?: true
  disabled?: boolean
  error?: true
  type?: 'button' | 'reset' | 'submit'
  style?: React.CSSProperties
  running?: boolean
  appearDisabled?: boolean
}

const Button: FC<ButtonProps> = ({
  id,
  isLoading,
  label,
  onClick,
  className,
  rank = 'primary',
  destructive = false,
  disabled = false,
  error = false,
  type = 'button',
  style,
  ...props
}) => {
  const fullClassName = `khh-button ${rank === 'pane' ? 'pane-button' : rank} 
  ${destructive ? 'destructive' : ''} 
  ${disabled || isLoading ? 'not-allowed' : 'allowed'}
  ${error ? 'error' : ''}
  ${className ?? ''}`

  return (
    <AnimateSharedLayout>
      <motion.button
        id={id}
        type={type}
        onClick={onClick}
        className={fullClassName}
        disabled={disabled}
        style={style}
        data-running={isLoading}
        {...props}
      >
        <AnimatePresence>
          {isLoading && (
            <motion.div
              data-test='button-loading-spinner'
              key='loading-spinner'
              layout
              initial={{ opacity: 0, width: '0rem', marginLeft: '0rem' }}
              animate={{ opacity: 1, width: '1rem', marginLeft: '1rem' }}
              exit={{ opacity: 0, width: '0rem', marginLeft: '0rem' }}
              transition={{ ease: 'easeInOut' }}
            >
              {/*Not displayed, for accessibility*/}
              <div className='loader'>Loading...</div>
            </motion.div>
          )}
        </AnimatePresence>
        <span>{label}</span>
      </motion.button>
    </AnimateSharedLayout>
  )
}

export default Button
