import { Nugget, NuggetInsertInput } from '../../../graphql/sdk.generated'
import { MutationFunction, useMutation } from 'react-query'
import { thingQueryKey, ThingQueryRes } from '../things/useThing'
import { refreshThingsForBundle } from './useThingsForBundle'
import { queryClient, sdk } from 'src/components'
import { useSelectedBundleIdOrThrow } from 'src/core/hooks/params/bundles'
import { handleSuccess } from '../../../util/handle-success'
import { recoverableErrorHandler } from '../../../util/handle-recoverable-error'

export type InsertThingMutationInput = NuggetInsertInput
export type InsertThingMutationRes = Nugget
export const insertThingMutationFn = (): MutationFunction<
  InsertThingMutationRes,
  InsertThingMutationInput
> => {
  return data => {
    return sdk
      .insertOneNugget({ data })
      .then(
        ({ insertOneNugget }) => insertOneNugget
      ) as Promise<InsertThingMutationRes>
  }
}

const errMsg = 'Could not create thing'

export const useInsertThingMutation = () => {
  const selectedBundleId = useSelectedBundleIdOrThrow()
  return useMutation<
    InsertThingMutationRes,
    Error,
    InsertThingMutationInput,
    void
  >(insertThingMutationFn(), {
    onSuccess: data => {
      handleSuccess('Created thing "' + data.title + '"')
      queryClient.setQueryData<ThingQueryRes>(thingQueryKey(data._id), data)
    },
    onError: recoverableErrorHandler(errMsg),
    onSettled: () => refreshThingsForBundle(selectedBundleId),
  })
}
