import { Trail } from '../../../graphql/sdk.generated'
import { MutationFunction, useMutation } from 'react-query'
import { bundleQueryKey } from './useBundle'
import { refreshBundlesForOrg } from './useBundlesForOrg'
import { useNavigate } from 'react-router-dom'
import { queryClient, sdk, useSelectedOrgOrThrow } from 'src/components'
import { handleSuccess } from '../../../util/handle-success'
import { handleRecoverableError } from '../../../util/handle-recoverable-error'

export type DeleteBundleMutationInput = string
export type DeleteBundleMutationRes = Trail

export const deleteBundleMutationFn =
  (_id: DeleteBundleMutationInput): MutationFunction<DeleteBundleMutationRes> =>
  () => {
    return sdk
      .deleteOneTrail({ query: { _id } })
      .then(
        ({ deleteOneTrail }) => deleteOneTrail
      ) as Promise<DeleteBundleMutationRes>
  }

const errMsg = 'Could not delete bundle'

export const useDeleteBundleMutation = (input: DeleteBundleMutationInput) =>
  useMutation<DeleteBundleMutationRes, Error, void, void>(
    deleteBundleMutationFn(input),
    {
      onSuccess: data => {
        handleSuccess('Deleted bundle')
        refreshBundlesForOrg(data.organization).then() // Dangle
      },
      onError: error => handleRecoverableError(errMsg, error),
      onSettled: () => queryClient.invalidateQueries(bundleQueryKey(input)),
    }
  )
