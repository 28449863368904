import { useParams } from 'react-router-dom'
import { useBundle } from '../data'
import { NotFoundError } from '../../../components/core/errors'

export const useSelectedBundleId = () => useParams()['bundleId']
export const useSelectedBundleIdOrThrow = () => {
  const bundleId = useSelectedBundleId()
  if (!bundleId) throw new Error('Could not get bundleId from path')
  return bundleId
}

export const useSelectedBundle = () => {
  const bundleId = useSelectedBundleId()
  if (!bundleId) return null
  return useBundle(bundleId)
}

export const useSelectedBundleOrThrow = () => {
  const selectedBundle = useSelectedBundle()
  if (!selectedBundle) throw new NotFoundError('Could not get selected bundle')
  return selectedBundle
}
