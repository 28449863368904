import * as Sentry from '@sentry/react'
import type { FallbackRender } from '@sentry/react/types/errorboundary'

import { ReactNode, Suspense, SuspenseProps, VFC } from 'react'
import { handleRecoverableError } from '../../../core/util/handle-recoverable-error'
import Loading from '../Loading'
import ErrorComponent from '../ErrorComponent'
import { NotFoundError } from '../../core/errors'

export type SectionBoundaryProps = {
  children: ReactNode
  ErrorFallback?: FallbackRender
  LoadingFallback?: VFC<SuspenseProps['fallback']>
  errorMessage?: string
}

// TODO: Does not reset error on navigation
const SectionBoundary = (props: SectionBoundaryProps) => {
  const {
    children,
    ErrorFallback = ErrorComponent,
    LoadingFallback = Loading,
    errorMessage,
  } = props

  const onError = (error: Error) => {
    if (error instanceof NotFoundError) return
    handleRecoverableError(errorMessage ?? 'An unknown error occurred', error)
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} onError={onError}>
      <Suspense fallback={<LoadingFallback />}>{children}</Suspense>
    </Sentry.ErrorBoundary>
  )
}
export default SectionBoundary
