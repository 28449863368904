import React from 'react'
import './ThingOrBundleImage.scss'

const ThingOrBundleImage = (props: { imageId: string; alt?: string }) => {
  const { imageId, alt } = props
  const src = `https://res.cloudinary.com/paradigms-to-practices/image/upload/c_scale,ar_1:1,c_fill,g_auto/v1636564332/${imageId}`
  return (
    <div className='image-container'>
      <img src={src} alt={alt} className='cursor-pointer' />
    </div>
  )
}
export default ThingOrBundleImage
